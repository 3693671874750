/*!
 * Font Awesome Pro 5.0.12 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@import 'fontawesome/_variables';
@import 'fontawesome/_mixins';
@import 'fontawesome/_icons';

@import 'fontawesome/fa-solid';
@import 'fontawesome/fa-light';
