/*******/
/*HOOKS*/
/*******/

/* viewNetworkHook */
.networkHookHeader, .networkHookItemLink {
	display: none;
}

#s5 {
	.networkHookOuter{
		margin: 0;
        padding: 34px 0 6px;

		.hookItemContainer{
			width: auto !important;
		}

		.slick-initialized .slick-slide{
			display: table;
		}

		.slick-slide > div {
			display: table-cell;
			text-align: center;
			height: 100px;
			vertical-align: middle;
		}
	}
}


.networkHookItemContainer:focus {
    outline: none;
}

/* JobBannerHook */
.jbbOuter {
	overflow: hidden;

	#jobBannerHookPhotoDiv, .jbbHeader, .jbbIwCustomer {
		display: none !important;
	}
    
    .jobbannerItemText,
	#jobBannerHookReadMoreDescription {
		color: $primary-color;
		font-size: 1.6rem;
		margin-bottom: 30px;
	}

	.jobBannerInner {
		width: 50%;
		float: left;
		position: relative;

		@include media-breakpoint-down(md){
			width: 100%;
			margin-bottom: 30px;
		}

		.cookie{
			position: absolute;
			right: 0;
			top: 21px;
			z-index: 9;

			.favorites_cookies_detail{
				position: relative;
				left: 0;
				right: 0;
				top: 0;

				.hide-by-default{
					display: none;
				}
			}
		}
	}

	.jobHookContentReloader {
		padding: 50px;
		background: $bg-color;
		margin: 0 10px;
		flex-direction: column;
		position: relative;
	}

	.location {
		color: $primary-color;
		font-size: $btn-font-size;
		font-weight: 600;
	    text-transform: uppercase;
	    margin-bottom: 14px;
	    letter-spacing: 0.2rem;

		::before {
            content: $marker-icon;
            display: inline-block;
            vertical-align: top;
            width: 13px;
            color: $btn-light;
            margin-right: 6px;
            font-size: 1.6rem;
            font-family: "Font Awesome 5 Pro" !important;
            font-weight: 600 !important;
        }
	}

	@include media-breakpoint-down(lg){

	}
    
    ul.slick-dots {
        text-align: center;
        list-style: none;
        font-size: 1rem;
        padding: 0;
        margin: 15px 0;
        
        li {
            display: inline-block;
            
            @include media-breakpoint-down(sm){
                margin: 0 2px;
            }
            
            button {
                font-size: 0;
                line-height: 2px;
                border-radius: 50%;
                padding: 6px;
                margin: 0 3px;
                border: 2px solid $btn-light;
                background-color: transparent;
                
                @include media-breakpoint-down(sm){
                    margin: 0;
                }
            }
        }
        
        li.slick-active {
            button {
                border: 2px solid $headings-color;
            }
        }
    }
}

.jbbFunctionName {
	a {
		color: $headings-color;
		font-size: 1.8rem;
		font-weight: bold;
		padding: 0 0 5px 0;
		line-height: 2.8rem;
		display: inline-block;
	}
}

.jobHookContentReloader a.jobbannerItemLink,
#jobBannerHookReadMoreDiv a {
	/*!*/
	@include btn-transparent;
	border-radius: 10px;
    text-transform: uppercase;
	padding: 10px 15px;
	font-size: $btn-font-size;
	font-weight: bold;
	letter-spacing: .2rem;
	width: 100%;
    text-align: center;
	transition: all 125ms ease-in-out;
	display: block;
	max-width: 250px;
	margin:0 auto;
    
    &:hover {
        background: $btn-light;
        color: $white;
    }
}


/* SearchHookVacancies */
.actJshOuter {
	background: $white;
    padding: 38px 42px 25px 42px;
	text-align: center;
	max-width: 322px;
	height: auto;
	margin: 0 auto;
	display: inline-block;

	.hookInput{
		margin-left: -42px;
		margin-right: -42px;
	}
	.actFullSearch {
		font-size: 1.4rem;
	}
}

.actHHeader {
    color: $headings-color;
    font-size: 2.4rem;
    font-weight: bold;
    margin: 0 0 20px 0;
}
.searchText,
.actHHeader > .tIntro {
	display: block;
	font-size: 1.4rem;
	color: $btn-light;
	font-weight: 500;
	margin-top: 10px;
    text-transform: uppercase;
}

.actHICrit.hookInput select {
	height: 39px;
	color: $btn-light;
	font-size: 1.4rem;
    padding: 0 0 0 85px !important;
    background: url(../_images/indooruitstroom/search-icon.png) no-repeat;
    background-size: 15px;
    background-position: 50px center !important;
	border: none !important;
	border-radius: 0;
    border-bottom: 1px solid #e6eaf1;
}

.actHIKw.hookInput input {
	height: 39px;
	color: $primary-color;
	font-size: 1.4rem;
    padding-left: 85px;
	border: none;
	border-radius: 0;
    border-bottom: 1px solid #e6eaf1 !important;
}

.actHIKw.hookInput:before {
    content: $search-icon;
    position: absolute;
    width: 15px;
    color: $secondary-color;
    font-size: 1.5rem;
    line-height: 3.9rem;
    padding-left: 50px;
    font-family: "Font Awesome 5 Pro" !important;
    font-weight: 900 !important;
}
.hookInput.actHInput.actHICrit:before {
    content: $functiongroup-icon;
    position: absolute;
    width: 15px;
    color: $secondary-color;
    font-size: 1.5rem;
    line-height: 4.3rem;
    padding-left: 50px;
    font-family: "Font Awesome 5 Pro" !important;
    font-weight: 900 !important;
}

.chosen-container.chosen-container-multi {
	min-height: 39px;
    height: 100%;
	color: $btn-light;
	font-size: 1.4rem;
    padding-left: 85px;
	border: none;
	border-radius: 0;
	background: none;
    border-bottom: 1px solid #e6eaf1 !important;

	.chosen-results {
		height: 176px;
		text-align: left;
	}

	.chosen-choices {
		white-space: nowrap;
		text-overflow: ellipsis;
		text-align: left;

		.search-choice:after {
			content: ",";
			display: inline-block;
		}

		.search-choice{
			float: none !important;
			white-space: nowrap;
		}
	}

	.chosen-drop{
		top: 100%;

		ul.chosen-results{
			li{
				padding: 10px 5px;

				&:before{
					content: '';
					margin: -4px 5px 0 0;
					display: inline-block;
					vertical-align: middle;
					background-image: url(../_images/indooruitstroom/facetCheck.png);
					width: 21px;
					height: 21px;
				}

				&.result-selected:before{
					background-image: url(../_images/indooruitstroom/facetCheckChecked.png);
				}
			}
		}
	}

	&.hidden {
		background: none;
	}

	.chosen-choices li.search-field input[type="text"] {
		color: $primary-color;
		font-size: 1.4rem;
	}
}

#asSearchHookKeywords.hidden {
	background: none;
}

.actJshOuter{
	.actHInput.actHICrit select {
		background: transparent url(../_images/indooruitstroom/add-query-icon.png) no-repeat;
		border-bottom: 1px solid #e6eaf1 !important;
	}

	.actHButton input {
		background: $headings-color;
		border-radius: 10px;
		text-transform: uppercase;
		width: 240px;
	    font-size: 1.2rem;
		font-weight: bold;
		letter-spacing: .2rem;
	    transition: background 125ms ease-in, color 125ms ease-in;
        
        &:hover {
            background: $btn-light;
        }
	}

	.actHButton {
		margin: 38px 0 10px;
	}
}

/*LOGIN*/
.catid95101 .contentTitle {
	text-align: center;
	margin: 0 0 30px 0;
}
.contentIntro .webUserHookOuter {
	text-align: center;
}
.contentIntro .webUserHookText {
	margin: 0 0 1em 0;
}
.ocdHookHeader {
	display: none;
}
.catid95101 .contentOuter {
	max-width: 700px;
	margin: 0 auto;
}
.webUserHookButton {
	display: inline-block;
}
/*FAVO*/
.cookie {
	display: inline-block;
	position: relative;
    width: 30px;
    vertical-align: middle;
    
    .hContainer {
        margin-bottom: 13px;
    }
}

.nshDetailsContainer a {
	width: 30px;
    height: 30px;
    position: relative;
    
    &:before {
        content: $favorite-icon;
        position: relative;
        width: 25px;
        color: $tertiary-color;
        font-size: 2.5rem;
        font-family: "Font Awesome 5 Pro" !important;
        font-weight: 900 !important;
    }
    &:hover:before {
        font-weight: 900 !important;
        color: $headings-color;
    }
}

.loginButtonMenu a {
	/*width: 30px;*/
    /*height: 30px;*/
    /*position: relative;*/
    
    &:before {
        content: $login-icon;
        position: relative;
        width: 25px;
        color: $tertiary-color;
        font-size: 2.3rem;
        font-family: "Font Awesome 5 Pro" !important;
        font-weight: 900 !important;
        top: 2px;
    }
    &:hover:before {
        font-weight: 900 !important;
        color: $headings-color;
    }
    &:after {
        display: none !important;
    }
}

.nshDetailsContainer a:after {
	display: none;
}
.nshDetailsContainer a:hover:after {
	display: inline-block;

}

.favouriteJobList {
	text-align: center;
	display: flex;
    flex-wrap: wrap;
	margin: 0 -10px;
	transition: all 100ms ease-in-out;

	h1 {
		margin-bottom: 40px;
		flex: 1 0 100%;
	}

	.favouriteJobListItem {
		margin: 0 10px 20px;
		position: relative;
		transition: opacity 0.4s ease-in-out;

		&.removed{
			opacity: 0.2;
			pointer-events: none;

			& > a{
				pointer-events: all;
			}
		}

		span {
			flex: 1 1 50px;
		}

        &::after {
            content: $favorite-icon;
            position: absolute;
            display: block;
            vertical-align: top;
            width: 33px;
            top: 10px;
            right: 10px;
            color: $headings-color;
            font-size: 3.6rem;
            font-family: "Font Awesome 5 Pro" !important;
            font-weight: 900 !important;
            transition: transform 100ms ease-in-out;
        }
	}

}
.favouriteJobList:hover .favouriteJobListItem:after {
	animation-duration: 1.5s;
	animation-iteration-count: 1;
	animation-name: animate-cookies;
}

@keyframes animate-cookies {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(.75);
	}
	100% {
		transform: scale(1);
	}
}

/*QUICK APPLY*/
.qAplOuter {
	display: none;
}
.qsPageOuter .buttonContainer a:after {
	content: url(../_images/applyLinkedin.png);
	margin: 0 0 0 4px;
	display: inline-block;
}
.aplEnrollIntro ul {
	display: none;
}
/*JOB DETAIL*/
.mDhOut, .rJhVOut, .chlOuter {
    padding: 30px 34px;
    display: flex;
	flex-direction: column;
	transition: all 125ms ease-in-out;
}
.chlOuter.stick {
    position: fixed;
    top: 0;
}
.rJhVT {
	margin: 30px 0;
	cursor: pointer;

    &:before {
        content: $chevron-circle-down-icon;
        position: absolute;
        vertical-align: top;
        width: 20px;
        left: 0;
        margin-top: 2px;
        margin-right: 20px;
        color: $btn-light;
        font-size: 2rem;
        font-family: "Font Awesome 5 Pro" !important;
        font-weight: 900 !important;
        transition: transform 125ms;
    }

	&.rotate-icon {
		&:before {
		    width: 20px;
		    height: 20px;
		    transform: rotate(-90deg);
		}
	}
}


.rJhVIt a {
	font-size: $default-font-size;
	color: $headings-color;
	font-weight: bold;
}

.mDhOut, .rJhVIc, .chlOuter {
	background: $white;
}

.mDhOut {
	margin-bottom: 30px;
	will-change: transform;
    transition: all 125ms ease-in-out;
}

.rJhVOut {
	padding: 0;
}

.rJhVIc {
	padding: 30px;
}

.rJhVIc {
	&.block {
		display: block;
	}

	margin-bottom: 11px;

	.rJhVIt {
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
.rJhVRegion {
	order: -1;
	color: $btn-light;
	text-transform: uppercase;
	font-size: $btn-font-size;
	letter-spacing: .2rem;
    font-weight: 600;
}
.rJhVRegion span {
	color: transparent;
	background: url(../_images/indooruitstroom/location-small-icon.png) no-repeat;
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
    top: 5px;
    margin-right: 9px;
}
.mDhT, .rJhVT, .chlTitle {
    font-size: 2rem;
	color: $headings-color;
	font-weight: bold;
	margin-bottom: 24px;
}
.rJhVT {
    margin-left: 40px;
}
.mDhItC {
	margin-bottom: 0;
}
.mDhOut .mDhSh {
	display: none;
}
.mDhIw,
.mDhOut .mDhItT {
	display: inline-block;
    min-width: 35px;
}
.mDhOut .mDhItT {
    text-align: center;
}
.mDhItT i.fas,
.jbdAddthis i.fas{
    font-style: normal;
    color: $btn-light;
    font-size: 1.7rem;
    margin: 0 17px 0 1px;
}
.mDhItT img {
    vertical-align: initial;
    margin: 0 15px 0 0;
    display: inline-block;
    width: 17px;
    height: auto;
    position: relative;
    top: 3px;
}
.mDhIw ul li {
	display: inline-block;
	color: $primary-color;
}
.mDhIw ul li:not(:last-of-type):after {
	content: ',';
}
.mDhItT,
.mDhIw {
	font-size: 1.4rem;
}
.mDhIw ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.jbdAddthis {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	transition: all 125ms ease-in-out;
	position: relative;
	cursor: pointer;

	img {
		margin-right: 22px;
	}

	.jbdAddthis-text {
		font-size: 14px;
        line-height: 2.7rem;
	}

	.addthis_inline_share_toolbox {
		position: absolute;
		text-align: center;
		top: 120%;
		left: 0;
		width: 100%;
		-webkit-box-flex: 1;
		-ms-flex: 1 0 100%;
		flex: 1 0 100%;
		will-change: visibility;
		pointer-events: none;
		opacity: 0;
		transition: all 125ms ease-in-out;
		padding: 10px 10px;
		background: #FFF;
		box-shadow: 0px 3px 4px -1px rgba(207,187,163,0.65);
		border-radius: 5px;
	}

	&:hover .addthis_inline_share_toolbox {
		pointer-events: all;
		opacity: 1;
		top: 100%;
	}
}

.jbdAddthis a[role="button"] {
    background:  $headings-color !important;
}

/*CONSULTANT*/
.chlOuter .chlItemContainer {
    display: flex;
    flex-direction: column;
}
.chlPhoto1 {
	order: -1;
}
.chlPhoto1 img {
    width: 300px;
	max-width: 100%;
	height: auto;
}
.chlOuter a {
	color: $headings-color;
}
.chlItemTitle {
	margin-bottom: 4px;
}
.chlItemTitle a {
	font-weight: bold;
	font-size: $smaller-font-size;
}
.chlItemWord {
	margin-bottom: 8px;
}
.chlSkype {
	font-size: $smaller-font-size;
	color: $btn-light;
	order: -1;
	margin-bottom: 2px;
	span {
		display: none;
	}
}
.chlEmail, .chlPhoneCell  {

}
.chlEmail a, .chlPhoneCell {
	font-size: 1.4rem;
	color: $primary-color;
}
.chlOuter .chlPhoneCell span {
    margin-right: 10px;
    color: transparent;
	width: 18px;
	height: 30px;
    display: inline-block;
	overflow: hidden;
	vertical-align: middle;
    
    &::before {
        content: $phone-icon;
        display: inline-block;
        vertical-align: top;
        width: 16px;
        color: $btn-light;
        margin-right: 6px;
        padding-left: 1px;
        font-size: 1.6rem;
        font-family: "Font Awesome 5 Pro" !important;
        font-weight: 600 !important;
        transform: scaleX(-1);
        filter: FlipH;
    }
}
.chlOuter .chlEmail span {
    margin-right: 10px;
    color: transparent;
	width: 18px;
	height: 29px;
    display: inline-block;
	overflow: hidden;
	vertical-align: middle;
    
    &::before {
        content: $email-icon;
        display: inline-block;
        vertical-align: top;
        width: 16px;
        color: $btn-light;
        margin-right: 6px;
        padding-left: 1px;
        font-size: 1.6rem;
        font-family: "Font Awesome 5 Pro" !important;
        font-weight: 600 !important;
        transform: scaleX(-1);
        filter: FlipH;
    }
}

/*JSL*/
.jslTitle {
	color: $headings-color;
	font-size: 1.8rem;
	font-weight: bold;
	margin: 0 0 8px 0;
}
/*.jslItemContainer {
    width: 185px;
    height: 27px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}*/
.jslEmployee, .jslLocation {
	display: none;
}
.jslFoto img {
	width: 100%;
	height: auto;
}
.jslInner {
	margin: 15px;
    background: #FFF;
	box-shadow: 0 0 10px #afafaf;
}
.jslText {
	padding: 36px 50px;
}
.jslText a {
	font-weight: bold;
	color: #2c2c2c;
	padding: 15px 0;
	display: inline-block;
}
.jslFunctionName a {
	color: $tertiary-color;
	padding: 0;
}

.hookItemTitle.jslFunctionName:before {
    content:url('../_images/home-footer-arrow.png');
    margin-right: 9px;
}

.jslItemContainer .jslLocation img {
	display: inline-block;
	vertical-align: middle;
	margin: -6px 10px 0 0;
}
/*JSH*/
.jsh {
	position: absolute;
	bottom: 0;
	width: 100%;
	background: url(../_images/jsh.png) no-repeat;
	background-size: cover;
	padding: 40px 0;
}
.jsh .actJshOuter {
	margin: 0;
}
.jsh .actHHeader {
	display: none;
}
.jsh .actHInput,
.jsh .actHButton {
	width: 33%;
	float: left;
	padding: 0 15px;
	margin: 0;
}
.jsh .actHBl {
	display: none;
}
.jsh select,
.jsh .chosen-container {
	background: $white url(../_images/selectArrowColor.png) no-repeat right 30px center;
	padding: 16px 60px 16px 30px;
	border: 0;
}
.jsh input[type=text] {
	background: $white;
	padding: 16px 30px;
}
.jsh input[type=submit] {
	padding: 14px 40px 13px 40px;
	width: 100%;
	min-width: auto;
	text-align: left;
	background: #FFF url(../_images/jshButtonArrow.png) no-repeat right 30px center;
}
