/************/
/*RESPONSIVE*/
/************/
.mobile,
input.mobile {
	display: none;
}

@include media-breakpoint-down(xl){
	.t3o .tTitle,
	.home h1,
	.t4o .tTitle,
	.home h2,
	.t4o .tIntro,
	.t5Title,
	.starter .contentTitle,
	.t8Title,
	.actRewriteTitle,
	.contact .headerPlaceholder h1,
	.contact .headerPlaceholder .text,
	.aplAplSAr {
		font-size: 5rem;
		line-height: 1;
	}
	.actMatch .actMatchItem {
		width: 50%;
		margin: 0 0 10px 0;
	}
	.qsPageOuter .buttonContainer a:after {
		margin: -5px 0 -4px 4px;
		vertical-align: middle;
	}
	.jsh input[type=submit] {
		padding:16px 40px 15px 40px;
	}
	.home .outer {
		padding: 70px 0 30px 0;
	}
	.actonomy main {
		padding: 50px 15px;
	}

	.t5o .tItemCon .cell .tBody p:first-child,
	.t8o .tItemCon .cell .tBody p:first-child {
		display: none;
	}
}

@media (max-width: $xl) and (min-width: 580px) {
	.actTopMenu {
		padding: 0;
	}
}

@include media-breakpoint-down(lg){
	.t3o .tTitle,
	.home h1,
	.t4o .tTitle {
		font-size: 5rem;
	}
	.home h2,
	.t4o .tIntro {
		font-size: 5rem;
	}

	.t3o .right {
		display: none;

	}

	.favouriteJobList {
		justify-content: center;
	}

	.algemene-content, .content-main, .content-details {
		padding: 30px 15px;
		height: auto!important;
		margin-bottom: 20px;
		background: $white;
	}
	.content-main {
		margin-bottom: 0;
	}
	.content-details {
		background: none;
	}
	.algemene-content {
		margin-bottom: 0;
		.col-md-8 {
			width: 100%;
		}
	}
	.jsh {
		padding: 20px 0;
	}
	main, #s2 {
		padding: 30px 0;
	}


	footer > .col:first-of-type {
		display: none;
	}

	#sticky {
		width: 100% !important
	}
	.stickyCon input[type="button"].jbdButApl {
		display: none;
	}

	.button,
	a strike,
	a s,
	input[type=button],
	input[type=submit],
	button {
		max-width: 264px;
	}

	.jobHookContentReloader + .jobHookContentReloader {
		margin-left: 10px;
	}

	.details {
		padding-top: 30px;
		margin-left: 0;
	}
	.addthis_inline_share_toolbox {
		visibility: visible;
	}

	.map-route {
		right: 50%;
		transform: translate(50%);
		width: fit-content;
	}

	.map-route input[type=submit] {
		width: initial;
	}

	#sticky.stick {
	    position: fixed;
	    top: 0;
	    z-index: 10;
	    border-radius: 0 0 0.5em 0.5em;
	}
}

@include media-breakpoint-down(md){
	.contactgegevens{
		text-align: center;
	}

	input[type="button"].jbdButApl  {
		position: fixed;
		bottom: 0;
		margin: 0;
		width: 100vw;
		left: 0;
		z-index: 100;
		border-radius: 0px;
	}

	.map-route.show {
		top: 150px;
	}

	.jobSearchContaner {
		position: absolute;
		right: 0;
		margin-top: -52px;
	}

	.mDhOut, .rJhVIc, .chlOuter {
		max-width: 100%;
	}

	.chlOuter{
		text-align: center;
	}

	.jbdButApl.clone {
		display: none;
	}
	.t4o .tSubHeader {
		line-height: 3.9rem;
	}
	footer .col .tSubHeader:after, footer .col .jslTitle:after {
		left: 40%;
	}
	.actItemText {
		padding: 30px 35px;
	}
	.jobBannerInner {
		justify-content: center;
		margin: 0;
	}
	#jobBannerHookReadMoreDiv a {
		max-width: 220px;
	}

	.t3o .col1 .tItemCon, .t3o .col2 .tItemCon {
		padding: 0;
	}

	#jobBannerHookReadMoreDiv a {
		padding: 10px;
		width: 100%;
	}
	.contact .t3o .tText {
		font-size: 5.4rem;
	}

	.facetListTitle a:before {
		margin-right: 8px;
	}
	.favorites_cookies .save input, .favorites_cookies .delete input {
		width: 30px;
	}
}

@include media-breakpoint-down(xs){

	input, select, textarea{
		width: 100%;
		max-width: 100%;
	}

	body {
		font-size: 1.6rem;
	}


	footer .col:after {
		content: none;
	}


	.actonomy main,
	.starter main,
	.contact main {
		padding: 30px 15px;
	}
	.actRewriteTitle,
	h1,
	.t5Title,
	.starter .contentTitle,
	.t8Title,
	.contact .headerPlaceholder h1,
	.contact .headerPlaceholder .text,
	.jobTitlePlaceholder h1.jbdTitle {
		font-size: 2.8rem;
		line-height: 2.8rem;
	}
	.vacTitle .actTitle {
		margin-bottom: .8rem;
	}
	.actItemTitle {
		max-width: 95%;
	}
	ul.buttonsList li {
		display: block;
		margin: 0 0 20px 0;
	}
	ul.buttonsList li:last-child {
		margin: 0;
	}
	ul.buttonsList li a strike,ul.buttonsList li a s {
		display: block;
		text-align: center;
	}

	.vacatures main {
		padding: 30px 0;
	}

	.button,
	a strike,
	a s,
	input[type=button],
	input[type=submit],
	button {
		font-size: 1.2rem;
	}
	.content a strike {
		width: 100%;
		font-size: 1.2rem;
		width: 264px;
	}

	.home h1,
	.aplAplSAr {
		font-size: 3.2rem;
	}
	.aplAplSAr {
		line-height: 3.4rem;
	}
	.home h2,
	.t4o .tIntro {
		font-size: 3.2rem;
	}

	.map-route.show {
		width: 93%;
	}


	.t1o .tIntro ul {
		list-style: none;
		margin: 0;
		padding: 0;
		position: absolute;
		bottom: 50px;
		left: 22px;
		right: 22px;
	}
	.t1o .desktop .tSubHeader {
		font-size: 4rem;
		margin-bottom: 25px;
	}
	.actonomy .t1o .tIntro ul,
	.jobdetail .t1o .tIntro ul {
		display: none;
	}
	.t1o .tIntro ul li {
		margin: 0 0 20px 0;
	}
	.t1o .tIntro ul li:last-child {
		margin:0;
	}
	.t1o .tIntro ul li a strike {
		display: block;
		text-align: center;
	}
	.vacTitle .actMlJc {
		max-width: initial;
		display: block;
		font-size: 4.6rem;
		margin-left: 0;
	}
	.vacTitle .actTitle:before {
		margin: 6px 21px 6px 15px;
	}

	.t3o .padding {
		padding: 50px 15px;
	}
	.t3o .tIntro,
	.t3o .col1 .tItemCon,
	.t3o .col2 .tItemCon {
		padding: 0;
	}
	.t3o ul li {
		margin: 0 0 20px 0;
		padding: 0;
	}
	.t3o ul li:last-child {
		margin: 0;
	}
	.t3o .right {
		display: none;
	}

	.t3o li:first-of-type {
		margin-right: 0;
	}
	.t3o .tIntro {
		display: none;
	}
	.t3o .col1 {
		position: initial;
		// left: 0;
		padding: 0 15px;
		width: 100%;
		height: auto;
	}
	.t3o .col1 img {
		transform: none;
		width: 100%;
    	height: auto;
		margin: 0 auto;
		display: block;
	}

	.t4o .col1 {
		padding: 0;
	}
	.t4o .col4 strike {
		display: block;
	}
	.t4o .tFoto img {
		height: 385px;
	}
	.t4o .tSubHeader {
		line-height: 4.2rem;
		font-size: 4.2rem;
	}

	.t6o ul li,
	.t6o ul li strike {
		display: block;
	}
	.t6o ul li {
		margin: 0 0 20px 0;
	}
	.t6o ul li:last-child {
		margin: 0;
	}

	.t7o .tIntro {
		line-height: 3rem;
	}
	.t7o .tFoto img {
		min-height: 216px;
		height: auto;
	}
	.t7o .tText p {
		font-size: 3.3rem;
		line-height: 3.3rem;
	}

	.home .outer {
		padding: 50px 0;
	}
	.home .outer p {
		margin: 0;
	}
	.home .outer ul {
		display: none;
	}
	.home .outer ul li,
	.home .outer ul li strike {
		display: block;
	}
	.home .outer ul li {
		margin: 0 0 20px 0;
	}
	.home .outer ul li:last-child {
		margin: 0;
	}

	#s5 {
		padding: 50px 15px;
	}
	.jslTitle {
		margin: 0 0 10px 0;
	}
	.jslText {
	    padding: 20px;
	}

	#s5 .networkHookHeader {
		display: none;
	}
	#s2 {
		.jobHookContentReloader + .jobHookContentReloader {
			margin-left: 0;
		}
		.jobHookContentReloader {
			min-height: 280px;
			margin-bottom: 25px;
		}
	}

	#footerTop {
		padding: 15px;
	}
	#footerTop #footerLogo {
		max-width: 60%;
		margin: 0 0 30px 0;
	}
	.t7o .col2 {
		display: block;
	}

	.jsh {
		background: none;
	}
	.jsh .actHInput,
	.jsh .actHButton {
		width: 100%;
		float: none;
		margin: 0 0 20px 0;
	}
	.jsh input[type=submit] {
		color: $white;
		background: url(../_images/jshButtonArrowWhite.png) no-repeat right 30px center;
	}

	.actJshOuter .actHButton input {
		max-width: 85%;
	}

	.custRewriteIntro {
		font-size: 1.6rem;
		line-height: 1.8rem;
	}
	.actSRFacetCont {
		max-width: 100%;
	}
	.actIc {
		margin: 0 0 30px 0;
	}
	.actIc:hover .actLayover {
		display: none;
	}
	.actItemImagePhoto1 {
		display: none;
	}
	.actItemText {
		padding: 0;
	}
	.actItemTitle a {
		font-size: 1.6rem;
	}

	.mDhItT,
	.mDhIw {
		font-size: 1.6rem;
	}
	.mDhItT {
		line-height: 1.2rem;
	}
	.mDhItC, .jbdAddthis, .mDhT {
		padding: 0 10px;
	}

	.addthis_inline_share_toolbox {
		margin-top: 20px;
	}
	.jdItemCon .jdItem:first-child .jbdTextSh {
		font-size: 2.8rem;
		line-height: 2.8rem;
	}
	.jobTitlePlaceholder {
		position: relative;
	}
	.mDhOut {
		padding: 20px 0;
	}
	.jbdButApl.clone {
		display: none;
	}

	.topBar .favorites_cookies .delete input, .topBar .favorites_cookies_detail div[id*=delete] input,
	.topBar .favorites_cookies .save input, .topBar .favorites_cookies_detail div[id*=save] input {
		padding: 10px 0;
	}

	.mDhT {
		text-align: center;
	}
	.jbdButApl.clone, .hlb3 strike {
		width: 100%;
	}

	.chlOuter {
		padding: 25px;
	}

	.qAplOuter {
		display: block;
	}
	.qAplHeader {
		font-size: 3.2rem;
		line-height: 3.2rem;
		text-align: center;
		margin: 0 0 40px 0;
	}
	.qAplTAplNl {
		margin: 0 0 20px 0;
	}
	.qAplTAplNl a {
		text-align: center;
	}
	.qAplBcApl {
		margin: 0 0 20px 0;
	}
	#jobBannerHookReadMoreDiv a {
		padding: 5px 15px;
		width: 100%;
		bottom: 10px;
	}
	.actPageNav {
		margin-left: 0;
	}


	.consDetTextPersonal > div {
		margin-bottom: 10px;
	}

	.consDetTextPersonal .textWord span {
		width: 100%;
		font-weight: bold;
		}
}

@media screen and (max-width: 460px) {

	.favouriteJobListItem {
		padding: 20px;
		max-width: 100%;
		justify-content: center;
		align-items: center;
	}
	.t1o .desktop .tSubHeader {
		font-size: 3rem;
		margin-bottom: 25px;
	}

	.outer .aplPrivAreaInput label {
		max-width: 100%;
	}
	.aplAplSOuter div.formInputTaC {
		padding: 10px;
	}
	#s2 .t2o {
		padding: 0 0 40px;
	}
	.contact .t3o {
		bottom: 2px;
	}
}
@media screen and (max-width: 320px)  {

	.vacTitle .actMlJc {
		font-size: 4.2rem;
	}
	.actHHeader {
		font-size: 2.1rem;
	}
	.actJshOuter .actHButton input {
		max-width: 80%;
		font-size: 1rem;
	}
	.vacTitle .actTitle:before {
		width: 50px;
		margin: 6px 14px 6px 15px;
	}
	.vacatures main {
		padding: 20px 0;
	}
	.jobSearchContainer {
		padding: 0;
	}
	.actIc {
		padding: 15px;
	}
	.actPageNav a {
		padding: 0 10px;
	}
	.contact .t3o .col-xlg-6 {
		position: relative;
		left: 15px;
	}
	.contact .t3o .tText {
		font-size: 4.4rem;
	}
	.algemene-content .col-md-8 {
		padding: 0;
	}
}
