/***********/
/*JOBDETAIL*/
/***********/
body.jobdetail, body.content {
	background: $bg-color;
}

body.jobdetail{
	@include media-breakpoint-down(md){
		margin: 0 0 50px 0; /* compensate height for sticky apply button */
	}

	.main{
		padding: 0;
	}
}

.details {
	padding-top: 100px;

	@include media-breakpoint-down(md){
		padding-top: 30px;
	}

	.jbdButApl{
		width: 100%;
	}
}

//solliteer  copy
input[type=button].jbdButApl.clone, .hlb3 strike {
	margin-bottom: 30px;
	margin-right: 0;
    background: $headings-color;
    border: 1px solid $headings-color;
}
input[type=button].jbdButApl.clone:hover {
    background: $btn-light;
    border: 1px solid $btn-light;
}

//maak alert
.hlb3 strike {
	/*!*/
	@include btn-transparent;
	width: 100%;

	&:hover {
		border: 1px solid $headings-color;
		color: $headings-color;
		background: none;
	}
}

.text ul li {
	list-style:outside;
	padding: 0;
	list-style: none;
	position: relative;
	padding-left: 20px;
}

.text ul li:before{
	content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    display: inline-block;
    border-radius: 2px;
    background: $btn-light;
    margin-right: 20px;
    vertical-align: middle;
    height: 4px;
    left: 0;
    top: 14px;
}

.jobTitlePlaceholder {
	position: absolute;
	bottom: 0;
	width: 100%;
	background: url(../_images/jsh.png);/*no-repeat*/
	padding: 39px 0;
}
.jobTitlePlaceholder h1.jbdTitle {
	color: $white;
	text-align: center;
	text-shadow: 2px 3.464px 5px rgba(0, 0, 0, 0.15);
	font-size: 4rem;
}
.jdItemCon .jdItem:first-child .jbdTextSh {
	font-size: 3.4rem;
	line-height: 1;
	margin: 0 0 30px 0;
}
.jbdPhoto1 {
	display: none;
}
.jdItemCon .jdItem blockquote {
	margin: 0;
	padding: 0;
	font-size: 2.2rem;
}
.jbdBc .jbdButPrint,
.jbdBc .jbdButMail {
	display: none;
}
.jbdBc .inlineShare {
	display: inline-block;
	margin: 10px 0 -10px 10px;
}
.stickyCon input[type="button"].jbdButApl {
	display: block;
	width: 100%;
	margin: 0 0 30px 0;
	max-width: 380px;
}
input[type=button].jbdButApl {
	/*!*/
	@include btn-default;
	margin-right:20px;
    background: $headings-color;
    border: 1px solid $headings-color;

	@include media-breakpoint-down(xs){
		margin-right: 0;
	}

	min-width: 220px;
	&:hover {
		background: $btn-light;
        border: 1px solid $btn-light;
	}
}
input[type=button].jbdButBack {
	/*!*/
	@include btn-transparent;
	&:hover {
		border: 1px solid $headings-color;
		color: $headings-color;
	}
}
.jobdetail input[type=button]:active {
	outline: none;
}

.jbdTextSh, .jbdText {
	font-size: $smaller-font-size;
}

.jbdTextSh{
	margin: 0 0 5px 0;
}

.jbdText {
	margin: 0 0 2em 0;
}
.jbdShLoc,
.jbdShCp,
.jbdCpContainer,
.jbdStatPub {
	display: none;
}
.favorites_cookies {
	float: right;
}
.favorites_cookies {
	position: absolute;
    right: 7px;
    top: 22px;
}
.favorites_cookies .save input,
.favorites_cookies .delete input,
.favorites_cookies_detail .hide-by-default input,
.favorites_cookies_detail div[id*=save-favorite-] > input,
.favorites_cookies_detail div[id*=delete-favorite-] > input {
    font-size: 0;
    background: none;
    position: absolute;
    width: 100%;
    height: 100%;
}
.favorites_cookies .save,
.favorites_cookies_detail .save-item-jobbanner,
.favorites_cookies_detail div[id*=save-favorite-] {
    width: 26px;
    height: 26px;
    position: relative;
    right: 20px;
    
    &:before {
        content: $favorite-icon;
        position: absolute;
        vertical-align: top;
        width: 23px;
        color: $tertiary-color;
        font-size: 2.3rem;
        font-family: "Font Awesome 5 Pro" !important;
        font-weight: 900 !important;
    }
    &:hover:before {
        font-weight: 900 !important;
        color: $headings-color;
    }
}
.save-item-jobbanner input {
    font-size: 0;
    padding: 0;
    background: none;
}
.favorites_cookies .delete,
.favorites_cookies_detail div[id*=delete-favorite-] {
    width: 26px;
    height: 26px;
    position: relative;
    right: 20px;
    
    &:before {
        content: $favorite-icon;
        position: absolute;
        vertical-align: top;
        width: 23px;
        color: $headings-color;
        font-size: 2.3rem;
        font-family: "Font Awesome 5 Pro" !important;
        font-weight: 900 !important;
    }
    &:hover:before {
        font-weight: 900 !important;
        color: $headings-color;
    }
}
.favorites_cookies input {
    outline-color: transparent;
}
.favorites_cookies_detail a {
	font-size: 0;
}
.favouriteJobListItem {
	margin: 15px 0;
	background: $white;
	box-shadow: 0 0 10px #afafaf;
	position: relative;
	padding: 30px 55px;

	display: flex;
	flex-direction: column;
	flex: 1 0 31%;
    max-width: 366px;
}
.favouriteJobListItem a {
	font-size: 1.8rem;
	font-weight: bold;
	flex: 1 1 45px;
    overflow: hidden;
}
.vacancy-header {
    display: flex;
    justify-content: space-between;
}
.jobdetail-descr .jbdOuter {
	position: relative;
}
.favorites_cookies_detail {
	position: absolute;
    right: -18px;
    top: -23px;
}
.jbdTextLoc {
	color: $tertiary-color;
    text-transform: uppercase;
	font-size: 1.2rem !important;
	letter-spacing: .2rem;
    font-weight: 600;
	margin: 0;
}
.jbdTextLoc span {
    width: 16px;
    height: 16px;
    display: inline-block;
    position: relative;
	top: 0;
	margin-right: 7px;
    
    &::before {
        content: $marker-icon;
        display: inline-block;
        vertical-align: top;
        width: 13px;
        color: $btn-light;
        margin-right: 6px;
        padding-left: 1px;
        font-size: 1.6rem;
        font-family: "Font Awesome 5 Pro" !important;
        font-weight: 600 !important;
    }
}
.jbdShLoc + .jbdTextLoc {
	display: none;
}

/*CONTACT*/
.contact .main, .content .main {
	padding: 0;
	background: $bg-color;
	position: relative;
}

.contact{
	.formInput {

		@include media-breakpoint-down(xs){
			display: block !important;
		}

		& > label {
		   display: inline-block;
		   color: $headings-color;
		   font-weight: bold;
		   line-height: 2.7rem;
		   font-size: $smaller-font-size;
	   }

	   & > textarea, input[type=text] {
			display: inline-block;
			max-width: 75%;
			padding: 6px;
			background: $white;
			border: 1px solid #d2d2d2;
			border-radius: 10px;
            line-height: 2.7rem;

		   	@include media-breakpoint-down(xs){
		   		width: 100%;
		   		max-width: 100%;
		   	}
	   }

	   & > textarea {
	   		height: 225px;
	   }
   }
}


.contact .formInput>textarea:focus, .contact input[type=text]:focus {
	outline: none;
	border: 1px solid $headings-color;
}

.algemene-content-wrapper{
	@include media-breakpoint-down(md){
		background: #FFF;
	}
}

.algemene-content {
	padding-top: 60px;
	padding-bottom: 60px;
	position: relative;
	background: $white;
}

.content-main {
    background: $white;
}

.content-main {
	.contentTitle {
		margin: 0;
	}
}

.jobdetail-descr{

	@include media-breakpoint-down(md){
		.algemene-content{
			padding-top: 30px;
			padding-bottom: 30px;
		}

		background: #fff;
	}
}

.algemene-content::after {
    content: ' ';
    position: absolute;
    width: calc((100vw - 100%) / 2);
    height: 100%;
    top: 0;
    left: calc((-100vw + 100%) / 2);
	background: #fff;
}

.contactgegevens .t9o img {
	margin-bottom: 35px;
	height: auto;
}

.algemene-content h1,
.outer.contentOuter h1,
.algemene-content .p-title {
	font-size: $default-font-size;
	color: $headings-color;
	font-weight: bold;
	margin: 0;
}
.algemene-content .text,
.outer.contentOuter .text {
	font-size: $smaller-font-size;
	line-height: 3rem;
}
body.contact .algemene-content .text {
	font-size: $smaller-font-size;
    /*font-weight: bold;*/
	line-height: 3rem;
    margin-bottom: 30px;
}

.contact .qsPIOuter .aplCsc > label {
	display: none;
}
.contact .qsPIOuter .aplCsc .g-recaptcha {
    float: right !important;
}
.contact .t2o .tIntro a strike,
.content .t2o .tIntro a strike,
.content-details a strike,
.contactgegevens a strike {
    width: 100%;
    margin-bottom: 20px;
}
.contactgegevens a strike {
    background: $headings-color;
    border: 1px solid $headings-color;
    
    &:hover {
        background: $btn-light;
        border: 1px solid $btn-light;
    }
}
.qsPIOuter .buttonContainer .button {
    background: $headings-color;
    border: 1px solid $headings-color;
    
    &:hover {
        background: $btn-light;
        border: 1px solid $btn-light;
    }
}
.contact .t2o .tItemCon, .content-details .chlOuter, .t9o .tItemCon {
	background: $white;
	padding: 30px 34px;
    margin-bottom: 30px;
}
body.content .chlTitle {
	display: none;
}
body.content .contentIntro,
body.content .contentIntro p {
	font-size: $smaller-font-size;
}
.contact .t2o .tSubHeader {
	font-size: $default-font-size;
}
.content-details .tItemCon em,
.contactgegevens .tItemCon em {
    margin-right: 10px;
    color: transparent;
    width: 18px;
    height: 30px;
	display: inline-block;
	position: relative;
    top: 0px;
    
    &::before {
        position: absolute;
        content: $phone-icon;
        display: inline;
        width: 16px;
        color: $headings-color;
        left: 2px;
        font-size: 1.6rem;
        font-family: "Font Awesome 5 Pro" !important;
        font-weight: 600 !important;
        transform: scaleX(-1);
        filter: FlipH;
    }
}
.content-details .tItemCon strike,
.contactgegevens .tItemCon strike {
    font-size: 0;
    margin-right: 10px;
    color: transparent;
    width: 18px;
    height: 30px;
	display: inline-block;
	position: relative;
    top: 0px;
    
    &::before {
        content: $email-icon;
        display: inline;
        width: 16px;
        color: $headings-color;
        margin-right: 6px;
        padding-left: 1px;
        font-size: 1.6rem;
        font-family: "Font Awesome 5 Pro" !important;
        font-weight: 600 !important;
    }
}
.map-route, .map-route .last-p  {
	display: none;
}
.map-route {
	position: absolute;
    top: 100px;
    right: 33.33333333%;
    background: #fff;
	// box-shadow: 0 0 10px rgba(174,174,174,0.4);
	box-shadow: 0 0 0 9999px rgba(0,0,0,0.5);
    padding: 20px;
    z-index: 98;
    width: 200%;
    max-width: 600px;

	#map_canvas {
		margin-bottom: 20px;
		width:100%;
	}
	#routeFrom {
		max-width: 50%;
		min-width: 220px;
    	margin-bottom: 10px;
		height: 50px;
		line-height: 50px;
	}
	.map-close {
		background-image: url(../_images/indooruitstroom/fancybox_sprite_jw2.png);
		position: absolute;
		top: -18px;
		right: -18px;
		width: 36px;
		height: 36px;
		cursor: pointer;
	}
}
.map-route.show {
	display: block;

	#directions.appear {
		height: 243px;
		overflow-y: scroll;
	}
}

//consultant page
.consDetOuter {
	padding: 20px 30px;
	max-width: 900px;
    margin: 0 auto;
	box-shadow: 0 0 9px 0px rgba(0,0,0,0.24);

	h1.consDetTitle {
		display: inline-block;
	}
}

// Actonomy
.details > .jbdAddthis{
	display: none; /* prevent javascript poppin */
}

