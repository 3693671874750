/* Link the variables to the specific colors */
$primary-color: #0d2240;    // Dark grey
$secondary-color: #4d6373;  // Dark brown
$tertiary-color: #303030;   // Light brown
$headings-color: #46358c;   // Dark brown
$btn-light: #b9ce00;        // Light brown
$white: #ffffff;            // White

$bg-color: #f3f6f9;         // Light grey

$stickyalpha: .65;
$sticky-header: rgba(#cfbba3, $stickyalpha);    // Light brown and Alpha

/* Fonts */
$main-font: 'Lato', sans-serif;
$menu-font: 'Montserat', sans-serif;
$fontpath: '../_font/';

$default-font-size: 1.8rem;
$smaller-font-size: 1.6rem;
$btn-font-size: 1.2rem;
$bigheader-font-size: 4.5rem;


/* ICONS */
$chevron-circle-down-icon: '\f13a';
$chevron-circle-right-icon: '\f138';
$marker-icon: '\f3c5';
$favorite-icon: '\f005';
$login-icon: '\f023';
$search-icon: '\f002';
$functiongroup-icon: '\f319';
$gewuren-icon: '\f017';
$opleidn-icon: '\f508';
$functiegr-icon: '\f0b1';
$dienstvb-icon: '\f073';
$page-icon: '\f138';
$phone-icon: '\f095';
$email-icon: '\f0e0';
$upload-icon: '\f382';
$plus-icon: '\f067';
$minus-icon: '\f068';
$icon-user: "\f508"; /* Icon used as user icon (portal) */


/* Sizes */
$sitewidth: 1172px;

/* Device sizes based on bootstrap */
// Extra small devices (portrait phones, less than 576px)
$xs: 575.98px;

// Small devices (landscape phones, less than 768px)
$sd: 767.98px;

// Large devices (desktops, 992px and down)
$lg: 991.98px;

// Extra large devices (large desktops, 1200px and up)
$xl: 1199.98px;

@mixin big-heading($bigheader-font-size:4.2rem) {
    color: $headings-color;
    font-size: $bigheader-font-size;
    font-weight: bold;
    margin: 0 0 20px 0;
}
@mixin btn-default()  {
	background: $btn-light;
	color: $white;
    border: 1px solid $btn-light;
	border-radius: 10px;
	max-width: 100%;
	text-transform: uppercase;
	font-size: $btn-font-size;
	font-weight: bold;
	transition: all 125ms ease-in-out;
}
@mixin btn-transparent() {
    background: none;
	border: 1px solid $btn-light;
	color: $btn-light;
}

@mixin center-txt() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin sidebarItem{
    -webkit-box-shadow: 2.925px 2.728px 5px 0px rgba(0,0,0,0.05);
    box-shadow: 2.925px 2.728px 5px 0px rgba(0,0,0,0.05);
    padding: 30px;
    border: 1px solid #f3f3f3;
    margin-bottom: 30px;
}

/*
    BOOTSTRAP VARIABLES BREAKPOINTS _variables.scss (bootstrap)
*/

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;
