/**********/
/*ACTONOMY*/
/**********/
.actonomy .main {
	padding: 100px 15px;
}
.actEquery {
	display: none;
}

.act-filter-toggle{
	display: none;

	@include media-breakpoint-down(md){
		display: block;
		width: 100%;
		position: fixed;
		top: 0;
		top: calc(100% - 50px);
		left: 0;
		background: $btn-light;
		color: #FFF;
		text-align: center;
		line-height: 50px;
		height: 50px;
		overflow: hidden;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
		z-index: 99999;

		&.open{
			top: 0;
		}

		&:active{
			background: $secondary-color;
		}
	}
}

.actSRFacetCont {
	.actSROf{
		@include media-breakpoint-down(md){
			overflow-y: scroll;
			position: fixed;
		    width: 100vw;
		    height: calc(100vh - 50px);
		    z-index: 99998;
		    background: #FFF;
		    top: 0;
		    left: 0;
		    padding: 30px 30px 30px 30px;
			pointer-events: none;
			transform: translateY(100%);
		    transition: all 0.2s ease-in-out;
			margin-top: 50px;

			&.open{
			    transform: translateY(0);
				pointer-events: all;
			}
		}
	}
}

.vacTitle {
	position: absolute;
    top: 30%;

	.actTitle {
		color: $white;
		font-weight: normal;
		font-size: 1.5rem;
		text-transform: uppercase;
		line-height: 1.5;
		letter-spacing: .2rem;
		margin: 0;

		&:before {
			content: "";
			height: 2px;
			width: 100px;
			background: $white;
			display: inline-block;
			margin: 6px 21px 6px 30px;
		}
	}

	.actMlJc {
		border-right: none;
		font-size: 6rem;
		line-height: 1;
		font-weight: bold;
		color: $white;
		display: block;
		display: none;

		@include media-breakpoint-down(lg){
			font-size: 4.2rem;
		}
	}
}

.actMlEq, .actMlNs, .actMlSb, .actIFwPd, .actIFwRef {
	display: none;
}

.optionListContainer {
   border-bottom: none;
   margin-bottom: 0px;

	.optionList ul {
		padding: 0px;
		margin: 0;

		li:nth-last-child(1){
			margin-bottom: 50px;
		}
	}
}

.facetListTitle {
	font-size: 2rem;
	margin-bottom: 2.5rem;
    padding-left: 40px;

	a {
		color: $headings-color;

        &:before {
            content: $chevron-circle-down-icon;
            position: absolute;
            vertical-align: top;
            width: 20px;
            left: 15px;
            margin-top: 2px;
            color: $btn-light;
            font-size: 2rem;
            font-family: "Font Awesome 5 Pro" !important;
            font-weight: 900 !important;
            transition: transform 125ms;
        }
	}

	a.rotate-icon {
		&:before {
			transform: rotate(-90deg);
		}
	}
}

.facetList {
	ul {
		padding: 0;
		li {
			padding: 0;
			margin-bottom: 8px;
			a {
				color: $primary-color;
				font-size: $smaller-font-size;
			}
		}
	}
}

.actSRCf .optionListTitle {
	display: none;
    font-size: 2rem;
    margin-bottom: 1rem;
    color: $btn-light;
}

.actLcMcrit, .actLcKeywords {
	// margin-bottom: 8rem;
	a {
		font-weight: bold;
	}
	a:before {
		content: '';
		display: inline-block;
		width: 17px;
		height: 17px;
		background-image: url(../_images/indooruitstroom/remove-icon.png);
		background-repeat: no-repeat;
		margin-right: 10px;
		position: relative;
		top: 2px;
	}
	ul, ul li {
		padding: 0;
		transition: all 100ms;
	}
	li:hover {
		a {
			text-decoration: line-through;
		}
	}
}
.facetFreq {
	color:$headings-color;
	font-weight: bold;
}
.jobTop {
    display: flex;
	justify-content: space-between;
    min-height: 26px;
}
.actSResContainer .location  {
	color: $tertiary-color;
	text-transform: uppercase;
	font-size: $btn-font-size;
	font-weight: 600;
	letter-spacing: .1rem;
    
	::before {
        content: $marker-icon;
        display: inline-block;
        vertical-align: top;
        width: 13px;
        color: $btn-light;
        margin-right: 6px;
        font-size: 1.6rem;
        font-family: "Font Awesome 5 Pro" !important;
        font-weight: 600 !important;
    }
}
.actRewriteTitle {
	font-weight: 300;
	font-size: 7rem;
	line-height: 7rem;
	margin: 0 0 30px 0;
}
.actRewriteTitle b {
	display: block;
}
.custRewriteIntro {
	max-width: 800px;
	margin: 0 auto 60px auto;
	color: $headings-color;
	font-size: 2.2rem;
	line-height: 2.7rem;
	text-align: center;
}
.actShNj {
	margin: 30px 0 0 0;
}
.actShNj,
.actTextNj {
	text-align: center;
}
.actBottomMenu div.actMlCJsa {
	border: 0;
	padding: 0;
	margin: 0;
}
.newsSQFormFrag {
	padding: 20px;
}
.newsSQFormFrag h1 {
	font-size: 3.5rem;
}
div.fancybox-skin {
	background: $white;
}
.actBottomMenu {
    /*!*/
	padding: 0;
}

/*ACT ITEMS*/
.actIc {
    margin: 0 0 20px 0;
	padding: 30px;
	background: $white;
	position: relative;

	.favorites_cookies{
		.hide-by-default{
			display: none;
		}
	}

	.actIf.itemFooter{
		display: none;
	}
}

.actLayover {
	display: none;
	text-align: center;
	font-size: 3rem;
	font-weight: 300;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: url(../_images/actHover.png) no-repeat;
	background-size: cover;
	color: $white;
	padding: 30px;
	line-height: 1;
}
.actIc:hover .actLayover {
	display: block;
}
.actLayover img {
	margin: 0 0 30px 0;
}
.actLayover span {
	font-weight: bold;
}
.actItemImagePhoto1 {
	display: block;
}
.actItemImage {
	float: none;
}
.actItemImage img {
	max-width: 100%;
	margin: 0;
	width: 100%;
	display: block;
}
.actItemText {
	padding: 30px 35px;
}
.actItemTitle {
	margin: 0;
	padding: 0;
    min-height: 35px;
}
.actItemTitle a {
	font-size: 1.8rem;
	color: $headings-color;
}

.actIc .itemDescription {
	margin: 0 0 12px 0;
	line-height: 3rem;
	font-size: $smaller-font-size;
}

.additional-info {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}
.additional-info > div {
	display: inline-block;
    margin: 0 30px 0 0;
}
.additional-info span {
	width: 17px;
    height: 19px;
    line-height: 1.7rem;
    display: inline-block;
    position: relative;
    margin-right: 4px;
    vertical-align: middle;
}
.gewuren-info span:before {
    content: $gewuren-icon;
    display: inline-block;
    vertical-align: top;
    width: 17px;
    color: $btn-light;
    font-size: 1.7rem;
    font-family: "Font Awesome 5 Pro" !important;
    font-weight: 600 !important;
}
.opleidn-info span:before {
    content: $opleidn-icon;
    display: inline-block;
    vertical-align: top;
    width: 17px;
    color: $btn-light;
    font-size: 1.7rem;
    font-family: "Font Awesome 5 Pro" !important;
    font-weight: 600 !important;
}

.regio-info span:before {
    content: $marker-icon;
    display: inline-block;
    vertical-align: top;
    width: 17px;
    color: $btn-light;
    font-size: 1.7rem;
    font-family: "Font Awesome 5 Pro" !important;
    font-weight: 600 !important;
}
.functiegr-info span:before {
    content: $functiegr-icon;
    display: inline-block;
    vertical-align: top;
    width: 17px;
    color: $btn-light;
    font-size: 1.7rem;
    font-family: "Font Awesome 5 Pro" !important;
    font-weight: 600 !important;
}
.dienstvb-info span:before {
    content: $dienstvb-icon;
    display: inline-block;
    vertical-align: top;
    width: 17px;
    color: $btn-light;
    font-size: 1.7rem;
    font-family: "Font Awesome 5 Pro" !important;
    font-weight: 600 !important;
}


/*.opleidn-info span {
	background: url(../_images/indooruitstroom/opleidn-icon.png) no-repeat;
}*/
.gewuren-info_item,
.opleidn-info_item,
.regio-info_item,
.functiegr-info_item,
.dienstvb-info_item {
	display: inline-block;
	color: $tertiary-color;
	font-weight: 400;
	font-size: 1.4rem;
}
.gewuren-info_item:after,
.opleidn-info_item:after,
.regio-info_item:after,
.functiegr-info_item:after,
.dienstvb-info_item:after {
	content: '/';
    margin: 0 0px 0 3px;
}
.gewuren-info_item:last-of-type:after,
.opleidn-info_item:last-of-type:after,
.regio-info_item:last-of-type:after,
.functiegr-info_item:last-of-type:after,
.dienstvb-info_item:last-of-type:after {
	content: '';
	margin: 0;
}

.actIc .readmore {
	font-weight: bold;
	margin: 5px 0 0 0;
	display: block;
	color: $primary-color;
}
.actMatch .actMatchItem {
	width: 33%;
	float: left;
	font-size: 1.6rem;
}
.actMatch > div img {
	margin: 0 10px 0 0;
	vertical-align: middle;
}
.actMlCrss,
.actMlCsvq,
.actMlCJsa {
	display: none;
}
.actPageNav {
	text-align: left;
	margin-left: 15px;
	padding: 40px 0 0 0;
	line-height: 42px;
}
.actPageNav a {
	color: $primary-color;
	font-size: 1.8rem;
	padding: 0 15px;
	font-weight: 400;
}
.actPageNav a:hover {
	text-decoration: underline;
}
.actPageNav a.actNext,
.actPageNav a.actLast,
.actPageNav a.actPrev{
	color: $headings-color;
	font-weight: bold;
	font-size: 0;
    position: relative;
}

a.pnNext.actNext, a.pnPrev.actPrev {
    content: '';
    display: inline-block;
	vertical-align: top;
    
    &::before {
        content: $page-icon;
        display: inline-block;
        vertical-align: middle;
        width: 25px;
        color: $tertiary-color;
        margin-right: 6px;
        font-size: 2.5rem;
        font-family: "Font Awesome 5 Pro" !important;
        font-weight: 600 !important;
    }
}

a.pnPrev.actPrev {
	transform: scaleX(-1);
}

.actPageNav a.actLast,
.actPageNav a.actFirst,
a.pnNext.actNext:first-of-type {
	display: none;
}
.actPageNav a.actActive {
	background: none;
	color: $headings-color;
	font-weight: bold;
}

/* Chosen */
div.chosen-container .chosen-drop {
	border: 0;
    width: 75%;
}
.chosen-container.chosen-with-drop .chosen-drop {
    left: 12.5% !important;
}
.chosen-container .chosen-results li,
.chosen-container-multi .chosen-choices li.search-choice span {
	line-height: 1 !important;
}
.actJshOuter div.chosen-container-multi .chosen-choices li.search-choice {
	border: 0;
	background: none;
	box-shadow: none;
	padding: 0 0 0 15px;
	float: none;
	display: inline;
	float: left;
}
.chosen-choices {
	clear: both;
}
.actJshOuter .chosen-container-multi .chosen-choices {
	white-space: nowrap;
	text-overflow: ellipsis;
}
.actJshOuter .chosen-container-multi .chosen-choices .search-choice:after {
	content: ",";
	display: inline-block;
}
.actJshOuter .search-choice + .search-field {
	display: none;
}
.actJshOuter .chosen-drop .active-result:before {
	content: url(../_images/img_bk/facetCheck.png);
	margin: -4px 5px 0 0;
    display: inline-block;
    vertical-align: middle;
}
.actJshOuter .chosen-drop .result-selected:before {
	content: url(../_images/img_bk/facetCheckChecked.png);
	margin: -4px 5px 0 0;
    display: inline-block;
    vertical-align: middle;
}
.actJshOuter div.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
	left: 2px;
}
.chosen-choices {
	margin: 0;
	padding: 0;
}
.chosen-choices input {
	line-height: 22px;
}

/*********/
/*MODULES*/
/*********/
/*******/
/*APPLY*/
/*******/
.aplAplSOuter,
.aplEnrollOuter,
.actonomy .qsPIOuter,
.qsOuter {
	max-width: 700px;
	margin: 0 auto;
}
.aplAplSOuter input[type=text],
.aplAplSOuter input[type=password],
.aplAplSOuter input[type=file],
.aplAplSOuter textarea,
.aplAplSOuter .explain,
.aplAplSOuter div.formInputTaC,
.aplAplSOuter .apllDate>div,
.aplEnrollOuter input[type=text],
.aplEnrollOuter input[type=password],
.aplEnrollOuter input[type=file],
.aplEnrollOuter textarea,
.aplEnrollOuter .explain,
.aplEnrollOuter div.formInputTaC,
.aplEnrollOuter .apllDate>div,
.actonomy .qsPIOuter input[type=text],
.actonomy .qsPIOuter input[type=password],
.actonomy .qsPIOuter input[type=file],
.actonomy .qsPIOuter textarea,
.actonomy .qsPIOuter .explain,
.actonomy .qsPIOuter div.formInputTaC,
.actonomy .qsPIOuter .apllDate>div,
.actonomy .qsOuter input[type=text],
.actonomy .qsOuter input[type=password],
.actonomy .qsOuter input[type=file],
.actonomy .qsOuter textarea,
.actonomy .qsOuter .explain,
.actonomy .qsOuter div.formInputTaC,
.actonomy .qsOuter .apllDate>div {
	max-width: 100%;
}

.aplEnrollOuter .formInput label,
.aplAplSOuter .formInput label {
	margin-bottom: 10px;
    font-size: 1.6rem;
}

.aplAplSOuter .explain,
.aplEnrollOuter .explain,
.qsOuter .explain {
	padding: 0 0 15px;
}
.aplAplSLink,
.aplAplSTitle {
	display: none;
}
.aplAplSAr,
.actonomy .qsPIOuter h1.title,
.aplEnrollOuter h1.title {
	font-size: 4.2rem;
	line-height: 4.2rem;
	margin: 0 0 30px 0;
	border: 0;
	padding: 0;
	font-weight: 300;
	text-align: center;
}
.aplAplSAr {
	color: $headings-color;
}
.actonomy .qsPIOuter h1.title,
.aplAplSAr span,
.aplEnrollOuter h1.title {
	font-weight: bold;
	display: block;
}
.aplAplSAr {
    font-size: 1.8rem !important;
}
.aplAplSAr span {
    font-size: 1.8rem;
    padding-bottom: 10px;
}
.outer .aplPrivAreaInput label {
	max-width: 35%;
}
.formInputTaC {
	font-size: $smaller-font-size;
	line-height: 1.5;
	height: auto;
}
.aplCsc label[for=captcha] {
	display: none;
}
.aplEnrollIntro,
.qsPageOuter > h2.subHeader {
	display: none;
}
.qsPageOuter > .text {
	font-size: 1.6rem;
    line-height: 3rem;
	text-align: center;
}
.aplAplyLi a {
	background: url(../_images/indooruitstroom/linkedin_small.png) no-repeat 0px 4px;
}
.fileUpButton{
    background: url(../_images/indooruitstroom/fileUpload.png) 95% 50% no-repeat;
    transition: opacity 0.2s ease-in-out;

    &:hover {
        background: url(../_images/indooruitstroom/fileUpload.png) 95% 50% no-repeat;
        opacity: 0.5;
    }
}
.aplStartOuter {
    .formInput>label {
        max-width: 100%;
    }
    
    h2 {
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
    }
    
    .fileUpParrent,
    .fileUpButton,
    input[type=text],
    input[type=password],
    input[type=file],
    select,
    textarea {
        width: 100% !important;
        max-width: unset;
    }
    
    .aplStartBc,
    .aplStartBcFpwd,
    .aplSignBc {
        text-align: right;
    }
    .aplCsc {
        .g-recaptcha {
            float: right!important;
        }
    }
    
    .aplStartFormFSgnUp {
        ul {
            list-style: none;
            
            li {
                padding: 0;
                
                &:before {
                    display: none;
                }
            }
        }
    }
}

.vacRes1Outer .actTitle,
.vacRes1Outer .actTopMenu{
	display: none; /* hide so it won't pop while loading javascript */
}

.outer.aplOuter.aplEnrollOuter .fileUpParrent,
.outer.aplOuter.aplEnrollOuter .fileUpButton,
.outer.aplOuter.aplAplSOuter .fileUpParrent,
.outer.aplOuter.aplAplSOuter .fileUpButton{
    width: 100%;
    max-width: 100%;
}
.outer.aplOuter.aplCpOuter .fileUpParrent,
.outer.aplOuter.aplCpOuter .fileUpButton {
    width: 100%;
}
