/******/
/*HLBS*/
/******/

/*HLB1*/
/**MOVED TO MEDIA QUERIES**/

/*HLB2*/
.hlb2 {
    position: absolute;
    right: 0;
    display: inline-block;
    font-size: 1.2rem;
    background-color: $headings-color;
    text-transform: none;
    transition: 0.2s;
    
    a {
        color: $white !important;
        font-weight: bold;
        letter-spacing: 1px;
        padding: 6px 20px;
        line-height: 2.7rem;
        
        &::before {
            content:url('../_images/icon-login.png');
            margin-right: 9px;
        }
    }
    
    &:hover {
        background-color: #b9ce00;
    }
}


/*HLB3*/