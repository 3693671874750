* {
	box-sizing: border-box;
}

body, html {
	margin:0;
	padding:0;
	font-family: $main-font;
	font-weight: 300;
	color:$primary-color;
}

html {
	font-size: 62.5%; /*THIS WILL MAKE 1EM THE SAME AS 10PX*/
}

body {
	font-size: $default-font-size;
	/*overflow-x: hidden;*/
}

label{
	margin-bottom: 5px;
}

.intro,
.text,
.tekstblokje,
.itemContainer,
.itemDescription,
.hookItemContainer,
.hookText,
.formText,
.moduleIntro,
.moduleTextm,
.error,
.tText {
	line-height:3rem;
}

.intro {
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 2em;
}

h1 {
    font-size: 4.2rem;
    line-height: 4.2rem;
    margin: 0 0 30px 0;
	color: $headings-color;
}

h2 {
	font-size: 1.6rem;
	line-height: 2.7rem;
	margin: 0;
	color: $headings-color;
}

a {
	text-decoration: none;
	color: $headings-color;
    font-weight: 400;

	&:hover {
		opacity: .8;
	}
}

img {
	max-width: 100%;
	display: inline-block;
	object-fit: cover;
}

.table {
	height: 100%;
	width: 100%;
	display: table;
}

.cell {
	height: 100%;
	display: table-cell;
	vertical-align: middle;
}

p {
	margin: 0 0 1em 0;
}

body.contact .outer .buttonContainer{
	text-align: right;
}

.outer ul {
	list-style: none;
	padding: 0;
	margin: 0 0 1em 0;
}

.aplStartOuter ul, .aplStartOuter ul li {
	padding: 0;
}

.apllDate>div>span  {
	max-width: 100%;

	@include media-breakpoint-down(sm){
		width: 100%;
	}
}

.buttonContainer .button, .buttonContainer button {
	max-width: 100%;
    margin-right: 0;
    padding: 15px 50px;

	@include media-breakpoint-down(sm){
		width: 100%;
        
	}
}

.buttonContainer.actEqueryBc .button {
    background: $headings-color;
    
    &:hover {
        background: $btn-light;
    }
}

ul.buttonsList {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
	   display: inline-block;
	   margin:0 30px 20px 0;
	   padding: 0;
   }
}

.starter ul.buttonsList {
	margin: 30px 0 0 0;
}

.contentText {
	margin: 0 0 2em 0;
}

.slick-slide:focus,
.slick-slide *:focus{
    outline:none;
}


/*
*	Framework
*/
.sw {
	max-width: $sitewidth;
	margin: 0 auto;
	padding: 0 15px;
}

.content-sw {
	position: relative;
}

@keyframes dropdown {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

/*
*	Header
*/
header {
    background-color: $white;
    position: absolute;
    z-index: 999;
	width: 100%;

    .row {
		margin: 0;
	}

	.top-wrapper{
		font-size: 0;
        
        ul li.loginButtonMenu a {
            font-size: 0 !important;
            margin-top: -6px;
        }
	}

	nav#hlb1{
		width: 100%;

		/* Desktop HLB1 */
		@include media-breakpoint-up(xl){
			display: inline-block;

			/* all levels of ul */
			ul{
			   list-style: none;
			   margin: 0;
			   padding: 0;

				li{
					a{
						text-decoration: none;
					}

					&.mainLevactive > a{
						font-weight: bold;
                        color: $btn-light;

						&:after {
							border-color: $headings-color;
						}
					}
                    &.mainLev.active > a{
                        font-weight: bold;
                        color: $btn-light;

                        &:after {
                            border-color: $headings-color;
                        }
                    }
				}

		   		& > ul {
	   				display: inline-block;
				}
	   		}

			/* first level */
			& > ul {
				display: inline-block;
                padding-top: 31px;

				& > li {
					display: inline-block;
					position: relative;

					& > a{
						font-size: 1.6rem;
						font-family: 'Montserrat', sans-serif;
						color: $primary-color;
						padding: 0px 16px;
						display: inline-block;
						transition: all 0.2s ease-in-out;

						&:after {
							content: '';
							display: block;
							border-bottom: 2px solid transparent;
							position: relative;
							top: 3px;
							transition: border-color 0.2s ease-in-out;
						}

						&:hover{
							opacity: 1;
                            color: $headings-color;

							&:after {
								border-color: $headings-color;
							}
						}
					}

					/* second level */
					& > ul {
						display: block;
						pointer-events: none;
						visibility: hidden;
						position: absolute;
						top: 100%;
						left: 0;
						z-index: 100;
						text-align: center;
						width: 200px;
						border-bottom-left-radius: 5px;
						border-bottom-right-radius: 5px;
						padding: 10px 0 0 0;
						margin: 0;
						overflow: hidden;
						opacity: 0;
						margin-top: 10px;
						transition: all 0.2s ease-in-out;

						& > li{
							background-color: $white;
                            padding: 16px 0;

							> a{
								color: #000;
								text-decoration: none;
								font-size: 1.8rem;
								padding: 0 5px;
							}

							&:nth-child(1){
								border-top-left-radius: 5px;
								border-top-right-radius: 5px;
							}
						}
					}

					&:hover ul{
						pointer-events: all;
						visibility: visible;
						opacity: 1;
						margin-top: 0px;
					}
				}
			}
		}

		/* Responsive HLB1 */
		@include media-breakpoint-down(lg){
			position: fixed;
			width: 390px;
			max-width: 100vw;
			right: 0;
			z-index: 200;
			background: $white;
			height: 100vh;
			transition: all 0.3s ease 0s;
			transform: translateX(100%);
			overflow: auto;
			z-index: 9999;
			padding: 60px 0;

			ul {
				list-style: none;
				margin: 0;
				padding: 0;

				li{
					a{
						text-decoration: none;
					}
				}
			}

			/* first level hlb */
			& > ul {
				& > li {
					& > a{
						font-size: 2.4rem;
						color: #010101;
						padding: 10px 22px;
						display: block;
						text-align: center;
					}

					&.mainLevactive{
						& > a{
							color: $headings-color;
							font-weight: bold;
						}
					}

					&.hasSub{
						& > span{
							padding: 20px 22px;
							position: absolute;
							right: 0;
							z-index: 99;
							cursor: pointer;
							transition: opacity 0.3s ease-in-out;
                            margin-right: 10px;
                            
                            &:before {
                                font-family: "Font Awesome 5 Pro" !important;
                                font-weight: 900 !important;
                                content: $plus-icon;
                                position: relative;
                                width: 20px;
                                color: $headings-color;
                                font-size: 1.6rem;
                            }

							&:hover{
								opacity: 0.5;
							}
						}
					}

					&.hasSub.itemOpen{
						& > span{
                            
                            &:before {
                                font-family: "Font Awesome 5 Pro" !important;
                                font-weight: 900 !important;
                                content: $minus-icon;
                                position: relative;
                                width: 20px;
                                color: $headings-color;
                                font-size: 1.6rem;
                            }
						}
					}

					/* Second level ul */
					& > ul {
						display: none;

						& > li{
							& > a{
								font-size: 1.8rem;
								color: #010101;
								padding: 15px 22px;
								display: block;
								text-align: center;
								background: #f1f1f1;
							}
						}
					}

					&.hasSub.itemOpen > ul{
						display: block;
						margin: 0 !important;
					}
				}
			}

			.cookie {
				left: 50%;
				transform: translate(-50%);
			}
		}
	}
}

/* Sticky header specifics */
.sticky header {
	position: fixed;
	top: 0;
    background: $white;
    /*box-shadow: 0 0 9px 0px $sticky-header;*/
    /*animation: dropdown 0.5s ease-in-out;*/
    border-bottom: 1px solid $sticky-header;
    
    @include media-breakpoint-up(xl){
		padding: 0;
	}
}

@include media-breakpoint-up(xl){
	body.sticky {
		header{
			nav#hlb1 {
				li.mainLevactive > a {
					color: $btn-light;
				}
                li.mainLev.active > a {
                    color: $headings-color;
                }

				ul > li {
					& > a{
						color: $primary-color;
					}

					&:hover > a {
						color: $headings-color;
					}
				}

				& > ul > li > ul{
					box-shadow: 0px 5px 5px -2px rgba(207,187,163,0.35);
				}
			}
		}
	}
}

/*
*	HAMBURGER
*/
.hamburger {
	width: 36px;
	height: 22px;
	float: left;
	margin: 60px 20px;
	cursor:pointer;
	display: none;
	position: absolute;
	right: 0;
	z-index: 999;

	@include media-breakpoint-down(lg){
		display: block;
	}

	.line {
		height: 4px;
		background: $secondary-color;
		border-radius: 20px;
		margin:0 0 5px 0;
		transition: transform ease .5s;

		&:nth-child(1) {
			width: 95%;
		}

		&:nth-child(2) {
			width: 85%;
		}

		&:nth-child(3) {
			width: 100%;
			transition: margin-top ease .5s;
		}
	}
}

/* Hamburger when sticky */
body.sticky{
	.hamburger {
		@include media-breakpoint-down(md){
			margin: 19px 20px;
            margin-top: 40px;
		}

		.line {
			height: 4px;
			background: $headings-color;
		}
	}
}

/* Hamburger when menu open */
body.menuOpen {
	.hamburger {
		width: 37px;
		position: fixed;
		z-index: 99999;

		.line {
			position: absolute;
			width: 36px;
			top: 43%;
			background: $secondary-color;

			&:nth-child(1) {
				transform: rotate(-45deg);
			}

			&:nth-child(2) {
				transform: rotate(45deg);
			}

			&:nth-child(3) {
				margin-top: -200px;
			}
		}
	}

	header{
		nav#hlb1{
			transform: translateX(0);
		}
	}
}

/*
*	logo
*/
.buttonContainerTop {
    position: relative;
}
#logo {
	display: inline-block;
    margin-top: 30px;

	a{
		display: inline-block;
		line-height: 50px;
		padding: 12px 0;

		color: $white;
	    font-family: $menu-font;
	    font-size: $default-font-size;
	    text-transform: uppercase;

		span {
			font-weight: bold;
		}

		img{
			display: block;
		}
	}
}

body.sticky {
	#logo {
		height: 80px;

		img{
		}

		a{
			color: $btn-light;
			padding: 0;
			line-height: 50px;
    		padding: 12px 0px;
		}

		span {
		    color: $headings-color;
		}

		@include media-breakpoint-down(md){
			height: 48px;

			img{
				max-height: 38px;
			}

			a{
				line-height: 38px;
				padding: 5px 0;
			}
		}
	}
}


#headerRight {
	float: right;
    margin-top: 20px;
}

.main {
	padding: 100px 0 70px;
}

.vacatures .main {
	padding: 60px 0 60px;
	background: $bg-color;
}

.fullwidth .main {
    padding: 60px 0;
}

/*
	Sections
*/

body.home .main {
    display: none;
}

#s1 {
	position: relative;
    padding-top: 110px;
}

#s2 {
	background: $white;
	padding: 95px 0px 41px;
}

#s3 {
	background: $bg-color;
}

#s5 {
	background: $bg-color;
}

.content .contentSubHeader {
    margin-bottom: 5px;
}

.main ul, .main ul li {
    padding: 0;
}

.content .contentText {
	margin-bottom: 45px;
}

.content-details,
.contactgegevens {
	position: relative;

	@include media-breakpoint-down(md){
		position: relative !important;
		top: 0 !important;
		left: 0 !important;
	}

	.content-details-scrollbox{
		padding-top: 90px;

		@include media-breakpoint-down(lg){
			padding-top: 15px;
		}
	}
}

.content a strike,.content a s {
	/*!*/
	@include btn-transparent;
}
.content a.hlb4 strike,.content a.hlb4 s {
	/*!*/
	@include btn-default;
	width: 264px;
	max-width: 100%;

	@include media-breakpoint-down(md){
		width: 100%;
	}
}
.content .details a strike,.content .details a s {
	padding: 20px;
    width: 100%;
    margin-bottom: 30px;
}
.content a strike:hover,.content a s:hover {
	background: $btn-light;
	color: $white;
}
.jobdetail #s5 {
    background: $white;
}
.jobdetail-descr {
    margin-bottom: 350px;
}


footer{
	background: $bg-color;

	#footerTop {
		padding: 20px 0 0;

		.row{
			display: flex;
			flex-wrap: wrap;

			.col {
				padding-top: 75px;
                padding-bottom: 75px;
				font-size: 1.6rem;
				line-height: 1.6rem;
                
                .t6ContactInfo {
                    margin-top: 26px;
                    
                    a:before {
                        content:url('../_images/icon-mail2.png');
                        margin-right: 9px;
                        display: inline-block;
                    }
                }
                
                .t6ContactInfo a:last-child:before {
                        content:url('../_images/icon-phone2.png');
                        margin-right: 9px;
                        display: inline-block;
                    }

				@include media-breakpoint-down(md){
					padding-top: 0;
                    padding-bottom: 0;
				}

				&:first-of-type:after {
					background: none;
				}
			}
		}
	}

	@include media-breakpoint-down(md){
		.t5o, .jslOuter {
			display: none;
		}

		.t6o{
			text-align: center;
            padding-bottom: 20px;
		}
	}
}

#footerLogo {
	margin: 0 0 40px 0;
}

#footerBottom {
	background: $headings-color;
    text-align: center;
    color: $white;
	padding: 8px 0;
	font-size: 1.2rem;
	line-height: 1;
    
    a {
        color: $white;
    }
    
    .sw {
        position: relative;
    }
}

/*
*	Scrolldown icon
*/
#scrolldown {
    position: absolute;
    bottom: 20px;
	left: 50vw;
	margin-left: -16.5px;

	@include media-breakpoint-down(md){
		display: none;
	}

	.icon-scroll {
	    border-radius: 50%;
	    box-shadow: 0 0 0 2px #fff inset;
	    height: 33px;
	    width: 33px;
	    cursor: pointer;
		position: relative;

		@keyframes scroll {
			0% {
				opacity: 1;
			}
			100% {
				opacity: 0;
				transform: translateY(20px);
			}
		}

		&:before {
		    content: '';
		    background-image: url(../_images/indooruitstroom/scrolldown.svg);
		    background-repeat: no-repeat;
		    background-size: cover;
		    width: 14px;
		    height: 14px;
		    top: 1px;
			left: 9px;
		    animation-duration: 1.5s;
		    animation-iteration-count: infinite;
		    animation-name: scroll;
			position: absolute;
		}
	}
}

/*
*	Forms
*/
input, select, textarea {
	outline-color: #cfbba3;
}
.button,
a strike,
a s,
input[type=button],
input[type=submit],
button,
.actMlCJsa a {
	font-size: $btn-font-size;
	font-weight: bold;
	color: $white;
	letter-spacing: .2rem;
	line-height: 20px;
	text-decoration: none;
	display: inline-block;
	font-family: $main-font;
	border:none;
	cursor: pointer;
	min-width: auto;
	text-align: center;
	background: $btn-light;
    border-radius: 10px;
    text-transform: uppercase;
    transition: background 125ms ease-in, color 125ms ease-in;
	padding: 15px;
    
    &:hover {
        background: $headings-color;
    }
}
.aplStartBcFpwd input {
	min-width: 280px;
}

.button:focus,
a strike:focus,
a s:focus
input[type=button]:focus,
input[type=submit]:focus,
button:focus {
	outline-color: transparent;
}
ul.slick-dots li button {
	min-width: auto;
}
select,
.chosen-container {
	padding: 10px 30px;
	font-size: 1.8rem;
	font-family: $main-font;
	font-weight: 300;
	-webkit-appearance: none;
	border: 0;
	border: 1px solid $btn-light;
    border-radius: 10px;
}
.chosen-container.chosen-with-drop .chosen-drop {
    top: 5px;
}
select {
	padding: 18px 30px !important;
}
.fancybox-inner select {
    padding: 8px 16px!important;
    outline: none;
}
.chosen-choices {
	padding: 0;
}
input[type=text],
input[type=file],
input[type=password],
textarea,
.formInputTaC {
    padding: 8px 16px;
	font-family: $main-font;
	border: 1px solid $btn-light;
    border-radius: 10px;
	font-size: 1.8rem;
	font-weight: 300;
}
input[type=text]:focus, input[type=file]:focus,
input[type=password]:focus, textarea:focus, .formInputTaC:focus {
    outline: none;
    border: 1px solid #7c6b5e;
}
// .outer .formInput>label {
// 	max-width: 35%;
// }
.error {
	color: red;
	margin: -15px 0 15px 0;
}
div.chosen-container .chosen-results li.highlighted {
	background-color: $btn-light;
	background-image: none;
}
.aplCsc img {
	display: inline-block;
}
.formInput.formSmallInput.aplCic input {
	max-width:220px;
}
.jobdetail .fancybox-title-float-wrap,
.jobdetail .fancybox-title-float-wrap .child {
    display: none;
    margin-bottom: 0;
}
.jobdetail .formInput select {
	max-height: 45px;
}
input[type=checkbox] {
    -webkit-appearance: none;
    background-color: $bg-color;
    border: 1px solid $btn-light;
    padding: 17px;
    border-radius: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    
    &:focus {
        outline: none
    }
    
    &:checked {
        background-color: $bg-color;
        border: 1px solid $btn-light;
        color: $btn-light;
        
        &:after {
            content: '\2714';
            font-size: 30px;
            position: absolute;
            top: 8px;
            left: 5px;
            color: $btn-light;
            line-height: 18px;
        }
    }
}
input[type=radio] {
    -webkit-appearance: none;
    background-color: $bg-color;
    border: 1px solid $btn-light;
    padding: 11px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    margin-bottom: -5px;
    outline: none;
    
    &:checked {
        background-color: $bg-color;
        border: 1px solid $btn-light;
        color: $primary-color;
        
        &:after {
            content: ' ';
            width: 12px;
            height: 12px;
            border-radius: 50px;
            position: absolute;
            top: 5px;
            left: 5px;
            background: $btn-light;
        }
    }
}
.formCheckboxContainer {
    padding-top: 0;
}
.aplCheckContainer > label,
.aplCheckContainer > .formCheckboxContainer > span {
    line-height: 36px;
}

/*********/
/*BUSJABS*/
/*********/
/*HOME*/
.home h1 {
	font-size: 7rem;
	line-height: 1;
}
.home h2 {
	font-size: 7rem;
	font-weight: 300;
	line-height: 1;
	margin: 0 0 30px 0;
}
.home .outer {
	text-align: center;
	padding: 100px 0 82px 0;/*100px 0*/
	max-width: 800px;
	margin: 0 auto;
}
.home .outer p {
	margin: 0 0 40px 0;
	font-size: 2.2rem;
}
.home .contentText {
	margin: 0;
}
.home .outer ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.home .outer ul li {
	display: inline-block;
	padding: 0 25px;
	margin: 0 0 1em 0;
}
.ckOuter .sw {
    line-height: 45px;
}