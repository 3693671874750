body.portal {
    padding: 0;
    margin: 0;
	background: url(../_images/backgroundwrapper-one.png) center bottom no-repeat;
	background-size: cover;

    .intro,.text,.tekstblokje,.itemContainer, .itemDescription,.hookItemContainer,.hookText,.formText,.moduleIntro,.moduleTextm, .error{
    	line-height:3.4rem;
    }
    h1,
    .pcHeader,
    .aplWorkFormTitle {
    	font-size: 2.8rem;
    	line-height: 2.8rem;
    	margin: 0 0 1em 0;
    	font-weight: bold;
    	text-align: left;
    }
    h1:before,
    .pcHeader:before,
    .aplWorkFormTitle:before,
    h1.aplProfTitle:before {
        font-family: "Font Awesome 5 Pro" !important;
    	content: $icon-user;
        color: $primary-color;
    	margin: 0 25px 0 0;
    	vertical-align: middle;
    	display: inline-block;
    }
    h2,
    .assetForm h1,
    #noteform h1 {
    	font-size: 2rem;
    	line-height: 2.4rem;
    	margin: 0 0 1em 0;
    }
    .assetForm h1:before,
    #noteform h1:before {
    	display: none;
    }
    h3 {
    	margin: 0;
    }
    a {
    	text-decoration: none;
    	color:$primary-color;
    }
    a:hover {}
    img {
    	max-width: 100%;
    	height: auto;
    	display: inline-block;
    }
    * {
    	box-sizing: border-box;
    }
    .table {
    	height: 100%;
    	width: 100%;
    	display: table;
    }
    .cell {
    	height: 100%;
    	display: table-cell;
    	vertical-align: middle;
    }
    p {
    	margin: 0 0 1em 0;
    }

    /******/
    /*FORM*/
    /*****/
    .buttonContainer {
    	margin: 1em 0 0 0;
    }
    .formInput label {
    	font-weight: bold;
    	padding: 10px 0;
    	text-align: left;
    }

    .apllDate select {
    	max-width: 100%;
    }

    .explain {
    	color: #cecece;
    	font-weight: normal;
    }

    .error {
    	color: red;
    	padding: 0 0 0 25%;
        margin: -15px 0 15px 0;
    }
    .qsPageOuter > .error {
    	font-weight: bold;
    }

    /***********/
    /*FRAMEWORK*/
    /***********/
    #mobileTopmenu {
    	display: none;
    }
    #pMenu {
    	width: 290px;
    	float: left;
    	background: #FFF;
    	height: 100vh;
    	overflow: auto;
    	margin: 0;
        border-right: 1px solid #f0f0f0;
    }
    #pContent {
    	width: calc(100% - 290px);
    	padding: 48px;
    	float: left;
    	height: 100vh;
    	overflow: auto;
    }
    .portalContainer,
    /*div.pcOuter,*/
    .aplProfOuter,
    .aplLdOuter,
    .aplCpOuter,
    .avaOuter,
    .webUserProfilePage0Outer,
    .aplListOuter,
    .jbdOuter,
    .candActO,
    .custJblOuter,
    .custAddJobOuter,
    .stsOuter,
    .custAskPub,
    .actSResultsCont,
    /*.actSRFacetCont,*/
    .optionListContainer,
    .custCandListOuter,
    .custJbdOuter,
    .custCandMultiple,
    .custOuterStart,
    .candAdvDetailOuter,
    .custCandOuter,
    .aplAplSOuter,
    .qsOuter,
    .aplStartOuter,
    .custNetwEditOuter,
    .csnBody,
    .supplOuter,
    .spVacsOuter,
    .spVacOuter,
    .custJobThankYouOuter,
    .custFinDetOuter {
    	background: #FFF !important;
    	border-radius: 3px;
    	padding: 40px;
    	margin: 0 55px 55px 0;
    	display: block;
    	max-width: 1200px;

        @include sidebarItem;
    }
    .logo {
    	text-align: center;
    	max-width: 1200px;
    	margin: 0 0 50px 0;
    }
    .outer .DIVCustomerMenu {
    	display: none;
    }
    /*********/
    /*BUSJABS*/
    /*********/

    /*********/
    /*BISJABS*/
    /*********/

    /*********/
    /*TEASERS*/
    /*********/
    /*TEASER1*/
    .t1o {
    	max-width: 1200px;
    	text-align: center;
    	margin: 0 55px 0 0;
    }
    .t1o:after {
    	content: "";
    	display: block;
    	clear: both;
    }
    .t1o .tItemCon {
    	width: calc(33.33333% - 36.6666px);
    	float: left;
    	background: #FFF !important;
    	border-radius: 3px;
    	padding: 40px;
    	margin: 0 55px 55px 0;
    	display: block;
    }
    .t1o .tItemCon:nth-child(3n+3) {
    	margin: 0 0 55px 0;
    }
    .t1o .tItemCon .tSubHeader {
    	font-weight: bold;
    	color:$primary-color;
    	font-size: 1.8rem;
    	text-align: center;
    	margin: 0 0 10px 0;
    }
    .t1o .tItemCon a {
    	font-size: 1.8rem;
    	color: #FFF;
    	font-weight: 500;
    	background: $primary-color;
    	padding: 10px 40px;
    	text-decoration: none;
    	display: inline-block;
    	font-family: 'Myriad Pro', sans-serif;
    	border:none;
    	cursor: pointer;
    	border-radius: 3px;
    	-webkit-appearance:none;
    	margin: 30px 0 -79px 0;
        display: block;
        text-align: center;
    }
    /******/
    /*HLBS*/
    /******/
    /*PROFILE MENU CANDIDATES*/
    .outer .mCCustomerLoggedIn {
    	display: none;
    }
    .myProfileMenu .menuLink,
    .mCCustomerLoggedIn .menuLink {
    	display: block;
    	border: 0;
    	margin: 0;
    	padding: 0;
    	border-top: 1px solid #f0f0f0;
    }
    .myProfileMenu .menuLink a,
    .mCCustomerLoggedIn .menuLink a {
    	color: #c4c4c4;
        border-left:4px solid transparent;
    	display: block;
    	padding: 20px;
    	line-height: 1;
    	font-size: 1.8rem;
    }
    .myProfileMenu .menuLink.active a,
    .myProfileMenu .menuLink:hover a,
    .mCCustomerLoggedIn .menuLink.active a,
    .mCCustomerLoggedIn .menuLink:hover a {
    	font-weight: bold;
    	border-left:4px solid $primary-color;
    	color: #2c2c2c;
    }
    .myProfileMenu .menuLink.active a:after,
    .myProfileMenu .menuLink:hover a:after,
    .mCCustomerLoggedIn .menuLink.active a:after,
    .mCCustomerLoggedIn .menuLink:hover a:after {
    	content: url(../_images/portal/menuActiveArrow.png);
    	float: right;
    }
    /*******/
    /*HOOKS*/
    /*******/
    .CMPFhItC {
    	text-align: center;
    	padding: 60px 0 30px 0;
    }
    .candidateProfileImg {
    	border-radius: 100%;
    	overflow: hidden;
    	display: inline-block;
    	width: 200px;
    	height: 200px;
    	margin: 0 0 10px 0;
    }
    .candidateProfileImg img {
    	display: block;
    }
    .candidateName {
    	font-weight: bold;
    	line-height: 1;
    	font-size: 2rem;
    }
    .candidateName span {
    	display: none;
    }
    .candidateAvailability {
    	line-height: 1.2;
    	color:#c2c2c2;
    }
    .candidateAvailability a {
    	display: none;
    }
    /*OCD LOGIN*/
    .ocdHOlogged {
    	background: #FFF !important;
        border-radius: 3px;
        padding: 40px;
        margin: 0 55px 55px 0;
        display: block;
        max-width: 1200px;
    }
    .wuhLoggedinProfile,
    .wuhLoggedinOut {
    	display: inline-block;
    }
    /******************/
    /*MODULES EMPLOYER*/
    /******************/
    /*COMPANY PROFILE*/
    #nwPPheader {
    	margin: 0 0 1em 0;
    }
    /*JOBLIST*/
    .tmplsett_filter td {
    	vertical-align: top;
    }
    .tmplsett_filter .buttonContainer {
    	margin: 0;
    }
    .jblItemContainer {
    	border-bottom: 1px solid #f0f0f0;
    }
    .jblItemContainer:last-child {
    	border-bottom: 0;
    }
    .jblItemDescr p {
    	margin: 0;
    }
    /*JOBDETAIL*/
    input.jbdButApl,
    input.jbdButPrint,
    input.jbdButMail {
    	display: none;
    }
    /*ACTONOMY CV SEARCH*/
    .actMlCrss,
    .actMlCsms {
    	display: none !important;
    }
    .optionListContainer a {
    	color: #737373;
    }
    .actSResultsCont {
    	width: calc(70% - 55px);
    }
    .actSRFacetCont {
    	width: 30%;
    	max-width: 350px;
    }
    .actTopMenu {
    	background: #f9f9f9;
    	padding: 30px;
    	margin: 0 0 1em 0;
    }
    .candRes2Outer .actTopMenu .menuLink label {
    	width: 200px;
    	display: inline-block;
    }
    .candRes2Outer .actTopMenu .menuLink .splitter {
    	width: calc(50% - 125px);
    	display: inline-block;
    }
    .optionListContainer {
        border:0;
        margin: 0 0 20px 0;
    }
    .optionListContainer a {
        text-decoration: none;
    }
    .facetListTitle a,
    .optionListTitle {
        color: #2c2c2c;
        font-size: 23px;
        line-height: 27px;
        margin: 0 0 10px 0;
        font-weight: normal;
    }
    .optionListContainer ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    .actSRCf li {
    	margin: 0 0 5px 0;
    }
    .actSRCf a:before {
    	content: "x";
        font-weight: bold;
        margin: 0 5px 0 0;
        background: $primary-color;
        color: #FFF;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        display: inline-block;
        text-align: center;
        line-height: 20px;
        float: left;
    }
    .actIc {
    	border-bottom: 1px solid #f0f0f0;
    	padding: 0 0 30px 0;
    	margin: 0 0 30px 0;
    }
    .actIc:last-child {
    	border-bottom: 0;
    }
    .actBottomMenu div {
    	border: 0;
    }
    .actBottomMenu a {
    	font-size: 1.8rem;
        color: #FFF;
        font-weight: 500;
        background: $primary-color;
        padding: 10px 40px;
        text-decoration: none;
        display: inline-block;
        font-family: 'Myriad Pro', sans-serif;
        border: none;
        cursor: pointer;
        border-radius: 3px;
        -webkit-appearance: none;
    }
    .candiLeft {
    	width: 215px;
    	float: left;
    }
    .candiLeft .candiPhoto {
    	width: 175px;
    	height: 175px;
    	border-radius: 100%;
    	overflow: hidden;
    	background-size: cover;
    	border:1px solid #eee;
    }
    .candiRight {
    	width: calc(100% - 215px);
    	float: left;
    }

    .candiRight .itemTitle.actItCvs {
    	padding: 25px 0 0 0;
    }
    .candiRight .itemTitle.actItCvs a {
    	font-size: 2rem;
    	font-weight: bold;
    }
    .candiRight .actIlCvsDescr {
    	margin: 0 0 1em 0;
    }
    .candiRight .itemLine {
    	font-size: 1.6rem;
    }
    .candiRight .actIl label,
    .candiRight .actIl>span {
    	padding: 0;
    }
    .candiRight .actCvsFlag {
    	margin: 1em 0 0 0;
    }
    .actCvsFlag span {
    	font-weight: bold;
    	border-radius: 3px;
    	padding: 5px 20px;
    }
    .actCvsFlag span.csrFlagViewed {
    	background:#009fe3;
    }
    .actCvsFlag span.csrFlagApplicant {
    	background: $primary-color;
    }
    .actCvsFlag span.csrFlagNew {
    	background: #3aaa35;
    }
    .actSResScoreCvs,
    .actSResStarsCvs {
    	margin: 25px 0 0 5px;
    }
    /*CANDIDATE LIST - WHEN FROM VACANCIES*/
    .tscFilterDiv {
    	background: #f0f0f0;
    	padding: 24px;
    }
    .tscFilterDiv .hookInput label {
    	display: block;
    	float: left;
    	width: 100%;
    	max-width: 25%;
    	min-height: 1px;
    	line-height: 43px;
    	font-weight: bold;
    }
    .custCandItemContainer {
    	border-bottom: 1px solid #f0f0f0;
    }
    .custCandItemContainer .itemTitle {
    	padding: 0;
    }
    .custCandStatusCont span span {
    	font-weight: bold;
    }
    #custCandStats {
    	text-align: left;
    	max-width: 300px;
    	float: right;
    	background: #f0f0f0;
    	padding: 24px;
    }
    .custCandMultiple .buttonContainer {
    	clear: both;
    }
    /*CANDIDATE DETAIL*/
    .ui-tabs-panel,
    div.ui-widget {
    	font-size: 1.8rem;
    	font-family: 'Myriad Pro', sans-serif;
    }
    .ui-widget-content.ui-tabs {
    	padding: 0;
    }
    .ui-tabs ul.ui-tabs-nav {
    	background: $primary-color;
    	border: 0;
    	border-radius: 4px 4px 0 0;
    	padding: 10px 10px 0;
    }
    .ui-state-default,
    .ui-widget-content li.ui-state-default {
    	background: #f7f7f7;
    	border: 0;
    	cursor: pointer;
    }
    .ui-state-active,
    .ui-widget-content li.ui-state-active {
    	background:#FFF;
    	border: 0;
    }
    .ui-widget-header {
    	background: #c4c4c4;
    	border: 0;
    }
    .actIl label {
    	text-align: left;
    }
    .candAdvDetailOuter .actTopMenu {
    	padding: 0;
    	margin: 0 0 1em 0;
    	background: none;
    	font-size: 1.8rem;
    	float: none;
    	text-align: right;
    }
    .candAdvDetailOuter .actTopMenu .menuLink {
    	border: 0;
    	margin: 0;
    	padding: 0;
    }
    .candAdvDetailPersonal {
    	position: relative;
    }
    .candAdvDetailPersonalPhoto {
    	width: 200px;
        height: 200px;
        border-radius: 100%;
        overflow: hidden;
        top: 0;
        padding: 0;
    }
    .candAdvDetailOuter h2.subHeader{
    	color: #c4c4c4;
    	font-weight: 300;
    	margin: -36px 0 0 60px;
    }
    .candAdvDetailOuter .candAdvResultsContainer {
    	padding: 40px 0 0 0;
    }
    /*SAVED CV SEARCHES*/
    .aplListTbl td {
    	text-align: left;
    }
    .newAgentFormFrag .jbdButton {
    	margin: 0 0 5px 0;
    }
    .actIlSqtipOutEmail input[name=sq_email] {
    	width:calc(100% - 40px) !important;
    }
    .actIlSqtipOutEmail input[type=checkbox]{
    	margin: 14px 0;
    }
    /*STATISTICS*/
    .stsVacTbl {
    	width: 100%;
    }
    .stsVacTbl th {
    	text-align: left;
    }
    .stsVacTbl td,
    .stsVacTbl th {
    	padding: 5px;
    }
    .stsVacTbl td span {
    	font-weight: bold;
    	margin: 0 5px 0 0;
    	display: none;
    }
    .stsVacTbl tr:nth-child(odd) td {
        background: #f9f9f9;
    }
    .stsGenTbl {
    	margin: 0 0 30px 0;
    }
    .stsGenTbl:after {
    	content: "";
    	display: block;
    	clear: both;
    }
    .stsGenTbl .stsItem {
    	width: 16%;
    	text-align: center;
    	float: left;
    	padding: 0 10px;
    }
    .stsGenTbl .stsItem:before {
    	margin: 0 0 30px 0;
    	display: block;
    }
    .stsGenTbl .stsNumber {
    	color:$primary-color;
    	font-weight: bold;
    }
    .stsItemTotal:before {
    	content:url(../_images/portal/stsTotal.png);
    }
    .stsItemActive:before {
    	content:url(../_images/portal/stsAcPub.png);
    }
    .stsItemInactive:before {
    	content:url(../_images/portal/stsNotPub.png);
    }
    .stsItemAppl:before {
    	content:url(../_images/portal/stsSoll.png);
    }
    .stsItemApplActive:before {
    	content:url(../_images/portal/stsSollPubl.png);
    }
    .stsItemAppInactive:before {
    	content:url(../_images/portal/stsInactive.png);
    }
    .stsLegend {
    	display: none;
    }
    .stsLegend.visible {
    	content: "";
    	background: rgba(63, 63, 63, 0.8);
    	position: fixed;
    	z-index: 1;
    	top: 0;
    	left: 0;
    	right: 0;
    	bottom: 0;
    	display: block;
    	padding: 0;
    	margin: 0;
    }
    .stsLegend .stsLegendInner {
    	background:#FFF;
    	border-radius: 3px;
    	padding: 30px;
    	z-index: 2;
    	font-size: 1.4rem;
    }
    .stsLegend.visible .stsLegendInner {
    	position: fixed;
    	width: 300px;
    	top: calc(50% - 150px);
    	left: calc(50% - 150px);
    }
    .stsInfoBox {
    	content: url(../_images/portal/info.png);
    	margin: -3px 0 0 10px;
    	vertical-align: middle;
    	display: inline-block;
    	cursor: pointer;
    }
    .stsLegendClose {
    	content: url(../_images/portal/close.png);
    	cursor: pointer;
    	float: right;
        margin: -18px;
    }
    /*VACANCY LIST*/
    .custJblOuter .jblIntro {
    	color: #c4c4c4;
    }
    /********************/
    /*MODULES CANDIDATES*/
    /********************/
    /*REMOVE ALL KINDS OF STUFF*/
    .aplProfOuter .pcHeader,
    .aplLdOuter .pcOuter,
    .aplCpOuter .pcOuter,
    .avaOuter .pcOuter,
    .aplListOuter .aplProfilestatus,
    .webUserProfilePage0Outer .Wup0candOld,
    .aplListOuter .pcOuter {
    	display: none;
    }
    /*DOSSIER*/
    .dossierList ul {
    	list-style: none;
    	padding: 0;
    	margin: 0;
    }
    .hqTbl td {
    	font-size: inherit;
    }
    /*DOCUMENTS*/
    .aplMFileLink {
    	padding: 0 0 0 25%;
    }
    .aplMFileLink .labelFile {
    	float: none;
    	display: inline;
    	font-weight: 300;
    }
    /*PROFILE COMPLETE*/
    .aplProfOuter {
    	text-align: center;
    }
    .aplProfTitle:before {
    	display: none;
    }
    .pcOuter {
    	margin: 0;
    }
    .pcIc {
    	width: 100% !important;
    	height: auto;
    	width: 100% !important;
        background: #FFF;
        border: 1px solid $primary-color;
        border-radius: 20px;
        margin: 0 0 1em 0;
    }
    .pcItem {
    	background: $primary-color;
    	width: 100%;
    	padding: 15px 0;
        height: auto;
        border-radius: 20px;
    }
    .pcWord {
    	color: #FFF;
    	padding: 2px 0;
    }
    .aplProfText,
    .aplProfTextApld {
    	display: none;
    }
    /*TIMESHEETS*/
    .tscFilterDiv {
    	text-align: left;
    }
    	.tscFilterDiv .buttonContainer {
    		margin-bottom: 0;
    	}
    		.tscFilterDiv .buttonContainer input.button {
    			margin-bottom: 0;
    		}
    .tscListDiv {
    	margin: 0 0 1em 0;
    }
    /*ACTIVE VACANCIES*/
    .shVacIWord span {
    	font-weight: bold;
    }
    /*MY PROFILE: applicationList.html*/
    .aplListTbl {
    	width: 100%;
    }
    .aplListTbl th {
    	text-align: left;
    	padding: 5px;
    }
    .aplListTbl td {
    	padding: 5px;
    }
    .aplListTbl tr:nth-child(odd) td {
    	background: #f9f9f9;
    }
    /*CHANGE PROFILE*/
    .qMSCvWrapper {
    	width: 100%;
    }
    .qMSCvHw {
    	display: block;
    }
    .approw {
    	border: 1px solid #48c8a9;
    	border-radius: 8px;
    	background: #cdf4ea;
    	color: #48c8a9;
    	padding: 10px;
    	margin: 0 0 1em 0;
    	line-height: 1;
    }
    /*EMPLOYMENT + EDUCATION*/
    li.aplEduFormItem td:nth-child(1),
    li.aplWorkFormItem td:nth-child(1) {
    	font-weight: bold;
    	width: 25% !important;
    }
    li.aplEduFormItem.Odd,
    li.aplWorkFormItem.Odd {
    	background: none;
    	border: 0;
    	margin: 0 0 15px 0;
    }
    li.aplEduFormItem.Even,
    li.aplWorkFormItem.Even {
    	background: none;
    	border: 0;
    	margin: 0 0 15px 0;
    }
    .aplWorkFormGroup {
    	display: none;
    	overflow: hidden;
    }
    td[id*=tdPosition] div {
    	padding: 10px 30px 10px 20px !important;
        font-size: 1.8rem;
        font-family: 'Myriad Pro', sans-serif;
        font-weight: 300;
        -webkit-appearance: none;
        border: 1px solid #cecece;
        border-radius: 22px;
        max-width: 350px;
        cursor: pointer;
        background: url(../_images/portal/arrowGrey.png) no-repeat right 20px center;
    }
    .aplEduFormItem > table td:last-child {
    	display: none;
    }
    .aplWorkOuter {
    	padding: 100px 0;
    }
    .aplWorkFormGroup {
    	background: #F9F9F9;
    	margin: 30px 0 30px;
    	padding: 30px;
    }
    /*APPLY*/
    .aplAplyTk,
    .aplAplyLi {
    	display: none;
    }
    /************/
    /*RESPONSIVE*/
    /************/
    @media(max-width: 1600px){
    	.candiLeft {
    		display: none;
    	}
    	.candiRight {
    		width: 100%;
    	}
    }
    @media(max-width: 1300px){
    	/*STATISTICS*/
    	.stsGenTbl .stsItem {
    		width: 100%;
    		float: none;
    		text-align: left;
    		margin: 0 0 10px 0;
    		padding: 0;
    	}
    	.stsGenTbl .stsItem:after {
    		content: "";
    		clear: both;
    		display: block;
    	}
    	.stsGenTbl .stsItem:before {
    		width: 47px;
    		margin: 0 30px 0 0;
    	}
    	.stsGenTbl .stsTxt {
    		width: calc(100% - 154px);
    		padding: 12px 0 0 0;
    		margin: 0 30px 0 0;
    	}
    	.stsGenTbl .stsItem:before,
    	.stsGenTbl .stsNumber,
    	.stsGenTbl .stsTxt {
    		float: left;

    	}
    	.stsGenTbl .stsNumber {
    		float: right;
    		width: 47px;
    		line-height: 48px;
    		text-align: right;
    	}
    	/**/
    	.candiFilterToggle {
    		position: fixed;
    		bottom: 0;
    		right: 0;
    		height: 100vh;
    		text-align: center;
    		background: #f9f9f9;
    		padding: 10px;
    		line-height: 1;
    		transition: all 0.3s ease 0s;
    	}
    	.candiFilterToggle:after {
    		content: url(../_images/portal/filter.png);
    		vertical-align: middle;
    		display: inline-block;
    		margin-top: calc(50vh - 15px);
    	}
    	.filterOpen .candiFilterToggle {
    	    right: 350px;
    	}
    	.actSResultsCont {
    		width: calc(100% - 45px);
    	}
    	.actSRFacetCont {
    	    position: fixed;
    	    padding: 30px 0;
    	    width: 390px;
    	    right: 0;
    	    top: 0;
    	    overflow: auto;
    	    z-index: 200;
    	    background: #FFF;
    	    height: 100vh;
    	    transition: all 0.3s ease 0s;
    	    -webkit-transform: translateX(100%);
    	    -moz-transform: translateX(100%);
    	    -o-transform: translateX(100%);
    	    transform: translateX(100%);
    	}
    	/*MENU OPEN*/
    	.filterOpen .actSRFacetCont {
    	    -webkit-transform: translateX(0);
    	    -moz-transform: translateX(0);
    	    -o-transform: translateX(0);
    	    transform: translateX(0);
    	}
    	.optionListContainer {
    		padding:20px 40px 0 40px;
    	}
    }
    @media(max-width: 1024px){
    	.portalContainer,
    	.aplProfOuter,
    	.aplLdOuter,
    	.aplCpOuter,
    	.avaOuter,
    	.webUserProfilePage0Outer,
    	.aplListOuter,
    	.jbdOuter,
    	.candActO,
    	.custJblOuter,
    	.custAddJobOuter,
    	.stsOuter,
    	.custAskPub,
    	.actSResultsCont,
    	.optionListContainer,
    	.custCandListOuter,
    	.custJbdOuter,
    	.custCandMultiple,
    	.custOuterStart,
    	.candAdvDetailOuter,
    	.custCandOuter {
    		margin: 0 0 55px 0;
    	}
    	/*TOP*/
    	#mobileTopmenu {
    		display: block;
    		background:#FFF;
    	}
    	.candidateProfileImg {
    		width: 40px;
    		height: 40px;
    		float: left;
    		margin: 0;
    	}
    	.candidateInfo {
    		display: inline-block;
    		text-align: left;
    		margin: 0 0 0 10px;
    		padding: 3px 0 0 0;
    	}
    	.CMPFhItC {
    		padding: 10px;
    		float: left;
    		line-height: 1;
    	}
    	.candidateAvailability {
    		font-size: 1.6rem;
    		line-height: 1;
    	}
    	#pMenu .CMPFhItC {
    		display: none;
    	}
    	/*MENU CLOSED*/
    	.menuOuterContainer {
    	    position: fixed;
    	    width: 390px;
    	    left: 0;
    	    z-index: 200;
    	    height: 100vh;
    	    transition: all 0.3s ease 0s;
    	    -webkit-transform: translateX(-100%);
    	    -moz-transform: translateX(-100%);
    	    -o-transform: translateX(-100%);
    	    transform: translateX(-100%);
    	}
    	/*MENU OPEN*/
    	&.menuOpen .menuOuterContainer {
    	    -webkit-transform: translateX(0);
    	    -moz-transform: translateX(0);
    	    -o-transform: translateX(0);
    	    transform: translateX(0);
    	}
    	/*HAMBURGER*/
    	.hamburger {
    	    width: 30px;
    	    float: right;
    	    margin: 13px 16px 4px 0;
    	    cursor:pointer;
    	}
    	.hamburger .line {
    	    height: 4px;
    	    background: #2c2c2c;
    	    border-radius: 2px;
    	    margin:0 0 6px 0;
    	}
    	/*CONTENT*/
    	#pContent {
    		width: 100%;
    		padding: 20px;
    		height: calc(100vh - 63px);
    	}
    	.portalContainer,
    	.aplProfOuter,
    	.aplLdOuter,
    	.aplCpOuter,
    	.avaOuter,
    	.webUserProfilePage0Outer {
    		margin: 0 0 20px 0;
    		padding: 20px;
    	}
    	/*CANDIDATE LIST*/
    	.candiFilterToggle {
    	    height: calc(100vh - 47px);
    	}
    	.actSRFacetCont {
    		height: calc(100vh - 47px);
    		top: 47px;
    	}

    }
    @media (max-width: 767px){
    	/*STATISTICS*/
    	.stsVacTbl thead {
    		display: none;
    	}
    	.stsVacTbl td {
    		display: block;
    		width: 50%;
    		float: left;
    		padding: 5px 10px;
    	}
    	.stsVacTbl td.title,
    	.stsVacTbl td.location {
    		width: 100%;
    	}
    	.stsVacTbl td span {
    		display: inline-block;
    		min-width: 32px;
    	}
    	/*CANDIDATE LIST*/
    	.candiRight .itemTitle.actItCvs {
    		padding:0;
    	}
    	.candiRight .actIl {
    		margin: 0 0 1em 0;
    	}
    	.candiRight .actIl label,
    	.candiRight .actIl>span {
    		width: 100%;
    		float: none;
    	}
    	.candiRight .actIl label {
    		line-height: 1;
    	}
    	.actSResultsCont {
    		width: 100%;
    	}
    	.candiFilterToggle {
    		height: auto;
    		width: 100%;
    	}
    	.candiFilterToggle:after {
    		margin: 0;
    	}
    	.filterOpen .candiFilterToggle {
    		right: 0;
    	}
    	.actSRFacetCont {
    	    -webkit-transform: translateY(100%);
    	    -moz-transform: translateY(100%);
    	    -o-transform: translateY(100%);
    	    transform: translateY(100%);
    		width: 100%;
    		max-width: 100%;
    	}
    	.filterOpen .actSRFacetCont {
    	    -webkit-transform: translateY(0);
    	    -moz-transform: translateY(0);
    	    -o-transform: translateY(0);
    	    transform: translateY(0);
    	    height: calc(100vh - 95px);
    	}
    	.actMlSb {
    		display: none;
    	}
    	/*CANDIDATE DETAIL*/
    	.candAdvDetailOuter h2.subHeader {
    	    font-weight: 300;
    	    margin: -25px 0 0 30px;
    	}
    	.candAdvDetailPersonalPhoto {
    		display: none;
    	}
    	.actIl label {
    		display: block;
    		float: none;
    		width: 100%;
    		padding: 0;
    		font-size: 1.4rem;
    		font-weight: normal;
    		color: #c4c4c4;
    	}
    	.actIl>span {
    		width: 100%;
    		float: none;
    		padding: 0;
    		font-weight: bold;
    		font-size: 1.6rem;
    	}
    	.actIlCad {
    		margin: 0 0 1em 0;
    	}
    	.candAdvDetailPersonalTable tr {
    		display: block;
    		margin: 0 0 1em 0;
    		border-bottom: 1px solid #f0f0f0;
    		padding: 0 0 1em 0;
    	}
    	.candAdvDetailPersonalTable tr:last-child {
    		margin: 0;
    	}
    	.candAdvDetailPersonalTable td {
    		display: block;
    		width: 100%;
    		font-weight: bold;
    		font-size: 1.6rem;
    	}
    	.candAdvDetailPersonalTable td.cadCVLeft {
    		font-size: 1.4rem;
    		font-weight: normal;
    		color:#c4c4c4;
    		width: 100%;
    	}
    	.candAdvDetailSH {
    		color: $primary-color;
    	}
    	.candAdvDetailTabbedSection table,
    	.candAdvDetailTabbedSection table tbody {
    		display: block;
    	}
    	.candAdvDetailPersonalTable p {
    		font-weight: normal;
    	}
    }
    @media (max-width: 480px) {
    	body,
    	select,
    	.chosen-container,
    	input[type=text],
    	input[type=file],
    	input[type=password],
    	textarea,
    	.formInputTaC {
    		font-size: 1.6rem;
    	}
    	h1,
    	.pcHeader,
    	.aplWorkFormTitle {
    		font-size: 2.2rem;
    	}
    	h1:before,
    	.pcHeader:before,
    	.aplWorkFormTitle:before {
    		content: url(../_images/portal/headerIconSmall.png);
    		margin: 0 12px 0 0;
    	}
    	h2 {
    		font-size: 1.8rem;
    	}
    	.intro, .text, .tekstblokje, .itemContainer, .itemDescription, .hookItemContainer, .hookText, .formText, .moduleIntro, .moduleTextm, .error {
    		line-height: 2.4rem;
    	}
    	.logo {
    		margin: 25px 0 25px 0;
    		padding: 0 25px;
    	}
    	/*SOLLICITATIES*/
    	.aplListTbl thead {
    		display: none;
    	}
    	.aplListTbl tr {
    		display: flex;
    		flex-direction: column;
    	}
    	.aplListTbl td {
    		display: block;
    	}
    	.aplListTbl td.aplListDateTime {
    		text-align: right;
    		font-size: 12px;
    		order: 3;
    	}
    	.aplListTbl td.aplListLink {
    		font-weight: bold;
    	}
    	.aplListTbl td:first-child {
    		font-weight: bold;
    	}
    	.ajaxWindow {
    		max-width: 80vw;
    	}
    	.fancybox-close {
    		background-image: url(../_css_global/fancybox/fancybox_sprite.png) !important;
    	}
    	/*EMPLOYMENT HISTORY*/
    	.aplEduFormItem td * {
    		color:#2c2c2c;
    	}
    	.aplEduFormItem td,
    	li.aplEduFormItem td:nth-child(1),
    	li.aplWorkFormItem td:nth-child(1) {
    		display: block;
    		width: 100% !important
    	}
    	/*CANDIDATE LIST*/
    	.actBottomMenu .menuLink {
    		display: block;
    	}
    	.actBottomMenu a {
    		margin: 0 0 10px 0;
    		display: block;
    		text-align: center;
    	}
    	/*CANDIDATE DETAIL*/
    	.candAdvDetailTopMenu a {
    		display: block;
    		text-align: center;
    		margin: 0 0 10px 0;
    	}
    	/*JOBDETAIL*/
    	.jbdPhoto1 {
    		float: none;
    	}
    	.jbdPhoto1 img {
    		margin: 0 0 1em 0;
    		display: block;
    		width: 100%;
    	}
    }
}
