/*********/
/*TEASERS*/
/*********/

/*
	TEASER1 & TEASER8
*/
.t1o .tIntro img,
.t8o .tIntro img {
	min-width: 100%;
	height: auto !important;
}

/*body.content .t8o .teaserinner .desktop.tItemContainer .tTitle:before,
body.fullwidth .t8o .teaserinner .desktop.tItemContainer .tTitle:before {
    display: none;
}*/

.t8o .teaserinner {
    @media (max-width: 1600px){
        top: 11% !important;
    }
}

.t1o, .t8o {
	position: relative;
	color: #FFF;

	/* slider dots */
	.sliderdots{
		position: absolute;
	    bottom: 0;
	    width: 100%;
		z-index: 99;
		pointer-events: none;

		@include media-breakpoint-down(sm){
			display: none;
		}

		.slick-dots{
			bottom: 0;
			list-style: none;
			text-align: right;
			width: 100%;
			left: 0;
			right: 0;

			li{
				display: inline-block;
				line-height: 0px;
				pointer-events: all;

				button{
					min-width: auto;
					font-size: 0;
				    height: auto;
				    padding: 0;
				    margin: 0 5px;
				    line-height: 15px;
				    width: 15px;
				    border-radius: 15px;
				    background: rgba(255, 255, 255, 0.25);
					transition: background 0.3s ease-in-out;
				}

				&.slick-active button{
					background: rgba(255, 255, 255, 0.75);
				}
			}
		}
	}

	/* jobsearchook in slider */
	.JobSearchHookTeaser1{
		position: absolute;
		top: 27.2%;
		width: 100%;
		display: none;
		pointer-events: none;

		@media (max-width: 1600px){
			top: 9%;
		}

		@media (max-width: $xl){
			position: relative;
			bottom: auto;
		}

		.jobSearchContainer{
			text-align: right;

			.actJshOuter{
				pointer-events: all;
			}

			@include media-breakpoint-down(lg){
				text-align: center;

				.actJshOuter{
					width: 100%;
					max-width: 100%;
					padding: 0px 0px 25px 0px;

					.actHHeader{
						margin: 30px 0 20px 0;
					}

					.hookInput{
						margin-left: -15px;
						margin-right: -15px;
                        
                        select {
                            color: $tertiary-color;
                        }
					}
				}
			}

			@include media-breakpoint-between(md, lg){
				.actHInput {
					width: 33.3%;
					float: left;
				}

				.actJshOuter{
					.hookInput{
						margin: 0;
						padding: 0 15px;
					}
				}

				.actHButton{
					width: 33.3%;
					float: left;
					margin: 0;
				}
			}
		}
	}

	/* slider inner content */
	.teaserinner {
		position: absolute;
		top: 18%;
		width: 100%;

		@media (max-width: 1600px){
			top: 6%;
		}

		@include media-breakpoint-down(sm){
			bottom: auto;
			top: 0;
			height: 100%;
		}
        @include media-breakpoint-down(lg){
            .col-sm-7 {
                width: 100%;
            }
        }

		.actTopMenu{
			padding: 0;
		}

		.menuLink.actMlJc{
			display: block;
			border: 0;
			font-size: 6rem;
            padding-right: 0;

			@include media-breakpoint-down(lg){
				font-size: 4.2rem;
			}
            @include media-breakpoint-down(sm){
				font-size: 3.2rem;
                padding-right: 0;
			}
		}

		.desktop.tItemContainer  {
			a strike {
				color: $white;
				background: rgba(255,255,255,0.3);
				border: 1px solid;
				min-width: 195px;
				border-radius: 10px;
				text-transform: uppercase;
				font-size: 1.2rem;
				font-weight: bold;
				letter-spacing: .2rem;
				transition: opacity 125ms ease-in;
                padding: 15px 35px;
                margin-top: 20px;
                max-width: none;
			}

			.tTitle, .title {
				font-size: 1.5rem;
				font-weight: 400;
				letter-spacing: .2rem;
				color: $btn-light;
				position: relative;
				margin: 0 0 0 40px;
				padding: 0;
				text-transform: uppercase;
				line-height: initial;
			}

			.tTitle:before, .title:before {
				content: '';
				display: inline-block;
				width: 104px;
				height: 2px;
				background: $btn-light;
				position: absolute;
				left: -115px;
				top: 50%;
			}

			.tSubHeader,
            .tSubHeader h1,
            .actMc {
				font-size: 6rem;
				color: $white;
				font-weight: bold;
				margin-bottom: 30px;
                line-height: 7.2rem;
                
                @media screen and (max-width: 1366px) {
                    font-size: 4.2rem;
                    line-height: 5.2rem;
                    margin-bottom: 20px;
                }
                
				@include media-breakpoint-down(lg){
					font-size: 3.2rem;
                    line-height: 4.2rem;
                    margin-bottom: 10px;
				}
			}
		}
	}

	.tFoto{
		img {
			height: auto;
			min-height: 250px;
			display: block;
		}
	}

	.slider{
		position: relative;
	}

	.slides{
		position: relative;

		.item{
			position: relative;
			overflow: hidden;
			display: block !important;
		}

		/* make sure no popping while loading js */
		.item:nth-child(n+2){
			display: none !important;
		}

		@include media-breakpoint-down(sm){
			display: none;
		}
	}

	/* make sure no popping while loading js */
	.slides.slick-initialized .item{
		display: block !important;
	}

	.mobileheader{
		display: none;
		position: relative;

		@include media-breakpoint-down(sm){
			display: block;
		}

		.teaserinner{
			.sw{
				height: 100%;

				.tItemContainerOuter{
					height: 100%;
					width: 100%;
					display: table;

					.tItemContainer{
						display:table-cell;
						vertical-align: middle;
						padding: 0 30px;
                        
                        @include media-breakpoint-down(sm){
                            padding: 0;
                        }

						.tText{
							text-align: center;
						}
					}
				}
			}
		}

		.tFoto{
			img{
				width: 100%;
			}
		}
	}
}

body.home .t1o .JobSearchHookTeaser1{
	display: block !important;
}

/*TEASER2*/
.t2o {
	padding-top: 100px;

	.tItemCon {
		padding: 20px 0 0;
	}

	.tSubHeader,
    .tSubHeader h2 {
		@include big-heading;
		line-height: 4.5rem;
		margin: 0 0 34px 0;
        
        @include media-breakpoint-down(md){
            font-size: 2.8rem;
        }
	}

	.tText {
		font-size: $smaller-font-size;
		line-height: 3rem;

		strong {
			color: $headings-color;
		}
	}

	@include media-breakpoint-down(lg){
		padding-top: 30px;
	}
}

#s2 .t2o {
    padding: 0 0 40px 0;
}

/*
	TEASER3
*/
.t3o {
    padding: 100px 0 188px;

	@include media-breakpoint-down(md){
		padding: 50px 0;
	}

	.tTitle {
		font-size: 7rem;
		margin: 0 0 30px 0;
		line-height: 1;
		font-weight: bold;
	}

	.tIntro {
		margin: 0 0 40px 0;
		font-size: 2.2rem;
		padding: 0 50px 0 0;
		position: absolute;
		top:115px;
		left: 115px;

		img{
			max-width: initial;
		}

		@include media-breakpoint-down(md){
			display: none;
		}
	}

	.tItemCon{
		&.column2{
			margin-top: 110px;
		}

		@include media-breakpoint-down(md){
			&.column1{
				img {
					height: auto;
					max-width: 100%;
					margin-bottom: 30px;
					display: none;
				}
			}

			&.column2{
				margin-top: 0px;
			}
		}

		ol {
			list-style: none;
			margin: 0;
			padding: 16px 0 0 0;
			margin-left: -10px;
			margin-right: -10px;

			li {
				display: inline-block;
				width: 50%;
				vertical-align: top;
				padding: 0 10px;

				@include media-breakpoint-down(sm){
					width: 100%;
					display: block;
					margin: 15px 0;
				}

				a{
					width: 100%;
					display: inline-block;

					strike{
						width: 100%;
					}
				}
			}
		}

		.tText {
			font-size: $smaller-font-size;
			line-height: 3rem;
		}
	}
}

.t3o .padding {
	padding: 100px 15px;
}

.t3o .tSubHeader,
.t3o .tSubHeader h2 {
	/*!*/
    line-height: 4.5rem;
	@include big-heading;
    
    @include media-breakpoint-down(md){
        font-size: 2.8rem;
    }
}
.t3o .tText b {
    color: $headings-color;
}
.t3o li a strike {
	/*!*/
	@include btn-default;
    background: $headings-color;
	color: $white;
    border: 1px solid $headings-color;
	letter-spacing: 0.2rem;
	min-width: auto;
	padding: 5px 15px;
}
.t3o li:hover a strike {
	background: $headings-color;
    border: 1px solid $headings-color;
}
.t3o li:last-of-type a strike {
	/*!*/
	@include btn-transparent;
    padding: 5px 30px;
}
.t3o li:last-of-type:hover a strike {
		border: 1px solid $headings-color;
		color: $headings-color;
}

.contact .t3o {
    padding: 0;
    position: absolute;
	top: 36.2%; /* equals 279px at fullheigth */

	@media (max-width: 1500px) and (min-width: 1024px) {
		top: 25%;
	}

	.col-xlg-6 {
		width: 100%;
	}
	.tSubHeader {
		margin: 0 0 20px 0;
		font-size: 1.5rem;
		font-weight: 400;
		letter-spacing: .2rem;
		color: $white;
		position: relative;
		margin-left: 40px;
		text-transform: uppercase;

		&:before {
			content: '';
			display: inline-block;
			width: 104px;
			height: 2px;
			background: #fff;
			position: absolute;
			left: -115px;
			top: 50%;
		}
	}
	.tText {
		line-height: 1;
		font-size: 6rem;
		color: $white;
		font-weight: bold;
	}
}

/*
	TEASER4
*/
.t4o {
	text-align: center;
	color: $white;

	.tTitle {
		font-size: 7rem;
		color: $headings-color;
		font-weight: bold;
		text-align: center;
		margin: 0 0 30px 0;
	}

	.tItemContainer {
		position: relative;
		padding: 84px 0;
		background-repeat: no-repeat;
		background-size: cover;

		.tSubHeader,
        .tSubHeader h2 {
			font-size: 4.5rem;
			font-weight: bold;
			margin-bottom: 20px;
			line-height: initial;
            color: $white;
		}

		@include media-breakpoint-down(md){
			padding: 50px 0;

			.tSubHeader{
				margin-bottom: 20px;
                
                @include media-breakpoint-down(md){
                    font-size: 2.8rem;
                }
			}
		}
	}

	.col1 {
		margin: 0 auto 40px auto;
		font-size: 2.2rem;
		padding: 0;/*0 50px 0 0*/
		text-align: center;
		max-width: 720px;
	}

	.col4 {
		text-align: center;
		padding: 40px 0 0 0;
	}

	.tIntro {
	    font-size: 7rem;
	    font-weight: 300;
	    line-height: 1;
	    margin: 0 0 30px 0;
	    text-align: center;
	}

	.tTextInner {
		font-size: 1.6rem;
		line-height: 3rem;
        max-width: 555px;
        margin: 0 auto;
        a {
            color: $white;
        }
	}

	.tText {
		font-size: 1.6rem;
		line-height: 3rem;
	}
    
    .tText p:first-child {
        margin-bottom: 30px;
    }
    
    .tText p:last-child {
        margin-bottom: 0;
    }

	strike {
		background: transparent;
		border: 1px solid $btn-light;
        color: $btn-light;
		text-transform: uppercase;
		font-size: 1.2rem;
		font-weight: bold;
		letter-spacing: 0.2rem;
		transition: all 125ms ease-in-out;
        padding: 15px 50px;
        
		&:hover {
			opacity: .7;
			background: transparent;
		}
	}
}

/*TEASER5*/
.t5o {

	.tItemCon {
		position: relative;
		text-align: center;
		display: block !important;
		cursor: pointer;

		.cell .tBody {
			line-height: 2.8rem;
			padding: 0 20px;

			b, strong{
				font-size: 2.2rem;
				text-transform: uppercase;
				margin: 30px 0 0 0;
			}

			p {
				margin: 0 0 25px 0;

				&:last-child {
					margin:0;
				}
			}
		}

		&:hover .tText {
			display: block;
		}

		.tText {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: url(../_images/t5overlay.png) no-repeat;
			background-size: cover;
			display: none;
			color: $white;
			font-size: 1.8rem;
		}

		.tSubHeader b {
			display: block;
		}
	}

	.t5Title {
		font-size: 7rem;
		line-height: 7rem;
		font-weight: 300;
		text-align: center;
		margin: 0 0 50px 0;

		b {
			display: block;
		}
	}

	.tSubHeader {
		/*!*/
		@include big-heading( $default-font-size );
		margin: 0;
	}
    
    .tText {
        
        ul {
            padding-left: 0;
            list-style: none;
            margin-top: 8px;
            
            li:before {
                content:url('../_images/home-footer-arrow.png');
                margin-right: 9px;
            }
            
            a {
                color: $tertiary-color;
            }
        }
    }
}

/*TEASER6*/
.t6o {
	text-align: left;
	.tIntro {
		p {
			margin: 0 auto 40px auto;
			font-size: 2.2rem;
			text-align: center;
			max-width: 720px;
		}
	}
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		text-align: center;
		li {
			display: inline-block;
			padding: 0 15px;
		}
	}
	.tSubHeader {
		/*!*/
		@include big-heading($default-font-size);
		margin: 0 0 10px 0;
	}
	.tText {
		em {
			color: $headings-color;
			font-weight: bold;
			font-style: normal;
			display: inline-block;
			width: 70px;
		}
	}
    a {
        color: $primary-color;
    }
}

/*TEASER7*/
#s7 {
	clear: both;
}

.t7o {
	position: relative;
	color: $white;
	text-align: center;
	font-size: 4.5rem;

	a {
		color: $white;
	}

	.tItemContainer{
		padding: 60px 0;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.tTextInner {
		/*!*/
		@include center-txt;
		max-width: 500px;
	}

	.tText {
		line-height: 4.5rem;

		p {
			margin: 0;
		}
	}
}
/*TEASER2*/
.t9o {

	.tItemCon {
		padding: 30px 0 0;
	}

	.tSubHeader {
		color: $headings-color;
        font-weight: bold;
        font-size: 1.8rem;
		line-height: 2rem;
		margin: 0 0 34px 0;
	}

	.tText {
		font-size: $smaller-font-size;
		line-height: 3rem;

		strong {
			color: $headings-color;
		}
	}

	@include media-breakpoint-down(lg){
		padding-top: 30px;
	}
}

.t10o {
    padding: 45px 0 20px 0;
    
    .tItemContainer {
        
        .tPhoto {
            text-align: center;
            font-size: 0;
            margin-bottom: 20px;
        }
        
        .tText {
            text-align: center;
            
            strike {
                background: transparent;
                border: 1px solid $primary-color;
                color: $primary-color;
                padding: 15px 15px;
                min-width: 185px;
            }
        }
    }
    
    .slick-slide img {
        display: inline-block;
    }
    
}

.t11o {
    padding: 70px 0 80px 0;
    
    .sw {
        max-width: 450px;
    }
    
    .tTitle,
    .tTitle h2 {
		@include big-heading;
		line-height: 4.5rem;
		margin: 0 0 45px 0;
        text-align: center;
        
        @include media-breakpoint-down(md){
            font-size: 2.8rem;
        }
	}
    
    .t11Slider {
    
        .tItemContainer {
            text-align: center;

            .tSubHeader {
                color: #bebebe;
                font-size: $smaller-font-size;

                strong {
                    color: $headings-color;
                    font-size: $default-font-size;
                }
            }

            .tText {
                padding: 15px 30px;
                font-size: $smaller-font-size;
            }
        }
        
        &::before {
            content:url('../_images/home-t11-before.png');
            position: absolute;
            display: block;
            vertical-align: top;
            width: 29px;
            top: -20px;
            left: 0px;
        }
        
        &::after {
            content:url('../_images/home-t11-after.png');
            position: absolute;
            display: block;
            vertical-align: top;
            width: 29px;
            bottom: 30px;
            right: -15px;
        }
    }
    
    
    ul.slick-dots {
        text-align: center;
        list-style: none;
        font-size: 1rem;
        padding: 0;
        margin: 15px 0;
        
        li {
            display: inline-block;
            
            @include media-breakpoint-down(sm){
                margin: 0 2px;
            }
            
            button {
                font-size: 0;
                line-height: 2px;
                border-radius: 50%;
                padding: 6px;
                margin: 0 3px;
                border: 2px solid $btn-light;;
                background-color: transparent;
                
                @include media-breakpoint-down(sm){
                    margin: 0;
                }
            }
        }
        
        li.slick-active {
            button {
                border: 2px solid $headings-color;
            }
        }
    }
    
}